//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class PaperError
{
	public error: string;
	public step: number;
}
export class PaperAcknowledgementViewModel
{
	public id: number;
	public text: string;
	public sortOrder: number;
	public checked: boolean;
}
export class ConferenceTaskGroupViewModel
{
	public id: number;
	public name: string;
	public colorCode: string;
}
export class ConferenceTaskStatusViewModel
{
	public id: number;
	public name: string;
	public colorCode: string;
	public statusType: ConferenceTaskStatusType;
}
export class TaskOrderUpdateViewModel
{
	public taskIds: number[];
}
export class GroupOrderUpdateViewModel
{
	public groupIds: number[];
}
export class ConferenceTaskCommentViewModel
{
	public id: number;
	public conferenceTaskId: number;
	public comment: string;
	public userId: number;
	public user: InstructorViewModel;
	public createdDate: any;
	public newDiscussionEntry: string;
	public discussionExpanded: boolean;
	public discussions: TaskDiscussionViewModel[];
}
export class TaskDiscussionViewModel
{
	public id: number;
	public user: InstructorViewModel;
	public body: string;
	public createdDate: any;
}
export class TaskSearchParameters
{
	public onlyMyTasks?: boolean;
	public searchText: string;
	public sortOrder?: TaskSortOrder;
}
export class ConferenceGroupWithTasksViewModel
{
	public id: number;
	public name: string;
	public colorCode: string;
	public tasks: ConferenceTaskViewModel[];
	public order: number;
	public expanded: boolean;
	public newTask: string;
	public checked: boolean;
	public editingHeader: boolean;
}
export class UpdateAssigneesViewModel
{
	public assigneeIds: number[];
}
export class UpdateTaskOrderAndGroupViewModel
{
	public newGroupId: number;
	public taskOrder: number[];
}
export class ConferenceTaskImportViewModel
{
	public name: string;
	public groupName: string;
	public description: string;
	public dueDate: string;
	public status: string;
	public assignedTo: string[];
}
export class ConferenceTaskViewModel
{
	public id: number;
	public name: string;
	public description: string;
	public dueDate?: any;
	public completedDate?: any;
	public createdById?: number;
	public completedById: number;
	public assignedToIds: number[];
	public assignedTo: UserViewModel[];
	public subTaskIds: number[];
	public commentCount: number;
	public comments: ConferenceTaskCommentViewModel[];
	public parentTaskId?: number;
	public status: ConferenceTaskStatusViewModel;
	public statusId: number;
	public order: number;
	public groupId: number;
	public checked: boolean;
}
export class SponsorConfigurationViewModel
{
	public conferenceSponsorInstructions: string;
	public conferenceExhibitorInstructions: string;
	public allowCreditCardsForSponsorships: boolean;
	public sponsorCurrency: string;
	public sponsorDeadlines: SponsorDeadlineViewModel[];
}
export class PaperConfigurationViewModel
{
	public callForPaperInstructions: string;
	public approvalTemplate: string;
	public rejectTemplate: string;
	public paperAcknowledgements: PaperAcknowledgementViewModel[];
}
export class PaperCustomContentAreasViewModel
{
	public id: number;
	public text: string;
}
export class PaperCustomQuestionsViewModel
{
	public id: number;
	public text: string;
	public order: number;
	public allowMultipleAnswers: boolean;
	public answers: PaperCustomQuestionAnswerViewModel[];
}
export class PaperCustomQuestionAnswerViewModel
{
	public id: number;
	public paperCustomQuestionId: number;
	public answer: string;
	public selectedAnswers: number[];
}
export class PaperTypeViewModel
{
	public id: number;
	public customQuestions: PaperCustomQuestionsViewModel[];
	public customContentAreas: PaperCustomContentAreasViewModel[];
	public conferenceId: number;
	public name: string;
	public title: string;
	public hiddenFromDropdown: boolean;
	public askDuration: boolean;
	public askForAuthors: boolean;
	public askForPaymentInfo: boolean;
	public askForCEUInfo: boolean;
	public askForAPACEUInfo: boolean;
	public askForChildPapers: boolean;
	public askForData: boolean;
	public askForDiscussant: boolean;
	public askForChair: boolean;
	public askForContentArea: boolean;
	public askForPrimarySpeaker: boolean;
	public askForPanelists: boolean;
	public askForBio: boolean;
	public askForLearningObjectives: boolean;
	public askForShortTitle: boolean;
	public titleRequired: boolean;
	public shortTitleRequired: boolean;
	public contentAreaRequired: boolean;
	public abstractRequired: boolean;
	public apaceuRequired: boolean;
	public learningObjectivesRequired: boolean;
	public durationRequired: boolean;
	public ceuRequired: boolean;
	public dataRequired: boolean;
	public ceuInstructorRequired: boolean;
	public ceuInstructorCVRequired: boolean;
	public childPaperTitleRequired: boolean;
	public childPaperDescriptionRequired: boolean;
	public childPaperPresenterRequired: boolean;
	public discussantRequired: boolean;
	public chairRequired: boolean;
	public checkPayableNameRequired: boolean;
	public checkAddressRequired: boolean;
	public bioIsRequired: boolean;
	public atLeastOnePersonHasBACBNumber: boolean;
	public atLeastOneBCBAIsRequired: boolean;
	public presenterAffiliationRequired: boolean;
	public requiredPanelistCount: number;
	public requiredAdditionalAuthorCount: number;
	public requiredPaperCount: number;
	public childPaperRequiredAuthorCount: number;
	public requiredPresenterCount: number;
	public availibleDurations: number[];
	public askForAcknowledgements: boolean;
	public screenLoginInstructions: string;
	public screenGeneralInstructions: string;
	public screenCEUInstructions: string;
	public screenChildPaperInstructions: string;
	public screenPeopleInstructions: string;
	public screenAcknowledgementInstructions: string;
	public screenPaymentInstructions: string;
	public screenReviewInstructions: string;
	public reviewCriteria: PaperTypeReviewCriteriaViewModel[];
	public ceuInstructorBACBNumberRequired: boolean;
	public askForCustomContentArea: boolean;
	public customContentAreaRequired: boolean;
	public allPresentersHeadshotIsRequired: boolean;
	public atLeastOneHeadshotIsRequired: boolean;
	public allPresentersBiographyIsRequired: boolean;
	public askForSpecialRequests: boolean;
	public customQuestionSurveyId?: number;
}
export class PaperTypeReviewCriteriaViewModel
{
	public id?: number;
	public text: string;
	public sortOrder: number;
	public result: boolean;
}
export class FinalizePaperModel
{
	public approve: boolean;
	public ceuInstructor: string;
	public ceuInstructorStatus: CEUInstructorStatus;
}
export class CallForPapersViewModel
{
	public id?: number;
	public paperTypeId: number;
	public paperTypeName: string;
	public title: string;
	public specialRequests: string;
	public shortTitle: string;
	public contentArea: string;
	public customContentAreas: string[];
	public abstract: string;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public anticipatedDurationMins?: number;
	public meetsCEURequirements?: YesNoUnsure;
	public meetsSupervisionCEURequirements?: YesNoUnsure;
	public meetsEthicsCEURequirements?: YesNoUnsure;
	public meetsAPACEURequirements?: YesNoUnsure;
	public cvAssetPath: string;
	public dataAssetPath: string;
	public dataNullReason: string;
	public ceuInstructorName: string;
	public ceuInstructorBACBNumber: string;
	public paperApprovalStatus: PaperApprovalStatus;
	public checkName: string;
	public address1: string;
	public customSurveyJSON: string;
	public address2: string;
	public city: string;
	public state: string;
	public zip: string;
	public hasSupportingData?: boolean;
	public paperCustomQuestionResponses: PaperCustomQuestionAnswerViewModel[];
	public submittingUser: InstructorViewModel;
	public assignedTo: InstructorViewModel;
	public chair: InstructorViewModel;
	public discussant: InstructorViewModel;
	public panelists: InstructorViewModel[];
	public presenters: InstructorViewModel[];
	public authors: InstructorViewModel[];
	public reviewers: InstructorViewModel[];
	public childPapers: PaperViewModel[];
	public errors: PaperError[];
}
export class ConvertToEventViewModel
{
	public title: string;
	public dayIndex: number;
	public roomId: number;
	public durationInMins: number;
	public mergeWith: number[];
}
export class PaperSubmissionViewModel extends CallForPapersViewModel
{
	public notes: PaperNotesViewModel[];
	public emails: PaperEmailViewModel[];
	public activities: PaperActivityViewModel[];
	public tags: PaperTagViewModel[];
	public reviews: PaperReviewModel[];
	public paperType: PaperTypeViewModel;
}
export class PaperTagViewModel
{
	public id?: number;
	public label: string;
}
export class PaperEmailAttachmentViewModel
{
	public contentLength: number;
	public contentType: string;
	public filename: string;
	public azureBlobName: string;
}
export class PaperActivityViewModel
{
	public id: number;
	public note: PaperNotesViewModel;
	public email: PaperEmailViewModel;
	public comment: PaperNotesCommentViewModel;
	public summary: string;
	public user: InstructorViewModel;
	public createdDate: any;
	public paperActivityType: PaperActivityType;
}
export class PaperEmailRecipientViewModel
{
	public id: number;
	public userId?: number;
	public recipientType: RecipientType;
	public emailAddress: string;
	public name: string;
}
export class PaperEmailViewModel
{
	public id: number;
	public paperId: number;
	public isSent: boolean;
	public from: InstructorViewModel;
	public recipients: PaperEmailRecipientViewModel[];
	public subject: string;
	public body: string;
	public createdDate: any;
	public attachments: PaperEmailAttachmentViewModel[];
}
export class PaperNotesViewModel
{
	public id: number;
	public author: InstructorViewModel;
	public body: string;
	public createdDate: any;
	public newCommentEntry: string;
	public commentsExpanded: boolean;
	public comments: PaperNotesCommentViewModel[];
}
export class PaperNotesCommentViewModel
{
	public id: number;
	public author: InstructorViewModel;
	public body: string;
	public createdDate: any;
}
export class PaperSubmissionFilterViewModel
{
	public paperApprovalStatus: PaperApprovalStatus[];
	public tags: PaperTagViewModel[];
	public beforeDeadline?: boolean;
	public paperSubmissionSort?: PaperSubmissionSort;
	public includedTypes: number[];
	public searchText: string;
}
export class SponsorOrganizationViewModel
{
	public id: number;
	public name: string;
	public selectedForMerge: boolean;
}
export class SurveySummaryViewModel
{
	public id: number;
	public name: string;
	public slug: string;
	public state: SurveyState;
	public responseCount: number;
	public modifiedDate?: any;
	public lastUpdatedBy: UserViewModel;
}
export class AddSurveyResultModel
{
	public dataJSON: string;
}
export class SurveyViewModel
{
	public id: number;
	public name: string;
	public mustBeLoggedIn: boolean;
	public state: SurveyState;
	public modifiedDate?: any;
	public lastUpdatedBy: UserViewModel;
	public schemaJSON: string;
	public slug: string;
}
export class SurveyWithDataViewModel
{
	public id: number;
	public name: string;
	public mustBeLoggedIn: boolean;
	public state: SurveyState;
	public modifiedDate?: any;
	public lastUpdatedBy: UserViewModel;
	public schemaJSON: string;
	public slug: string;
	public responses: SurveyResultViewModel[];
}
export class SurveyResultViewModel
{
	public id: number;
	public respondent: UserViewModel;
	public dataJSON: string;
	public createdDate: any;
}
export class ListCampaignsViewModel
{
	public id: number;
	public title: string;
	public fromName: string;
	public fromAddress: string;
	public templateThumbnail: string;
	public templateCategory: string;
	public isTemplate: boolean;
	public subject: string;
	public previewText: string;
	public openCount: number;
	public clickCount: number;
	public totalCount: number;
	public campaignType: CampaignType;
	public status: CampaignStatus;
	public sentDate?: any;
	public scheduledDate?: any;
	public modifiedDate?: any;
	public lastUpdatedBy: UserViewModel;
}
export class CampaignSummary
{
	public id: number;
	public title: string;
	public fromName: string;
	public fromAddress: string;
	public subject: string;
	public previewText: string;
	public htmlContent: string;
	public jsonContent: string;
	public openCount: number;
	public clickCount: number;
	public totalCount: number;
	public campaignType: CampaignType;
	public status: CampaignStatus;
	public sentDate?: any;
	public scheduledDate?: any;
	public modifiedDate?: any;
	public lastUpdatedBy: UserViewModel;
}
export class TestEmailViewModel
{
	public recipients: number[];
	public impersonateUser?: number;
}
export class CampaignListViewModel
{
	public id: number;
	public title: string;
	public conferenceId?: number;
	public listType: CampaignListType;
	public filter: CampaignFilter;
	public listSize: number;
}
export class CampaignValidationResult
{
	public errors: CampaignValidationError[];
	public totalCount: number;
}
export class CampaignValidationError
{
	public message: string;
}
export class CampaignTemplateViewModel
{
	public id: number;
	public htmlContent: string;
	public jsonContent: string;
	public name: string;
	public description: string;
	public previewImage: string;
}
export class CampaignViewModel
{
	public id: number;
	public title: string;
	public fromName: string;
	public fromAddress: string;
	public fromAddressDomain: string;
	public fromAddressUser: string;
	public subject: string;
	public previewText: string;
	public sendPushNotification: boolean;
	public pushNotificationText: string;
	public pushNotificationSubject: string;
	public htmlContent: string;
	public jsonContent: string;
	public isMarketing: boolean;
	public campaignType: CampaignType;
	public status: CampaignStatus;
	public sentDate?: any;
	public scheduledDate?: any;
	public recipients: number[];
	public excludedUsers: number[];
	public modifiedDate: any;
}
export class PaperSubmissionSummaryViewModel
{
	public id: number;
	public paperTypeId: number;
	public paperTypeName: string;
	public title: string;
	public paperSubmissionDate?: any;
	public anticipatedDurationMins?: number;
	public shortTitle: string;
	public paperApprovalStatus: PaperApprovalStatus;
	public conferenceName: string;
	public conferenceSlug: string;
	public contentArea: string;
	public submittingUser: InstructorViewModel;
	public assignedTo: InstructorViewModel;
	public tags: PaperTagViewModel[];
	public score: number;
	public reviewCount: number;
	public currentUserReviewed: boolean;
	public checkedForMerge: boolean;
	public convertedToEvent: boolean;
	public reviews: PaperReviewModel[];
}
export class PaperViewModel
{
	public title: string;
	public description: string;
	public presenter: InstructorViewModel;
	public authors: InstructorViewModel[];
}
export class PlaidToken
{
	public linkToken: string;
	public expiration: any;
	public hostedLinkUrl: any;
	public rawJson: any;
	public error: any;
	public requestId: string;
	public statusCode: string;
	public isSuccessStatusCode: boolean;
}
export class PublicTokenModel
{
	public public_token: string;
}
export class ServedAdViewModel
{
	public id: number;
	public clickUrl: string;
	public auctionId: any;
	public marginAssetPath: string;
	public bannerAssetPath: string;
	public videoAssetPath: string;
}
export class AdCampaignViewModel
{
	public id: number;
	public name: string;
	public active: boolean;
	public dailySpend: number;
	public maxBidImpression: number;
	public maxBidClick: number;
	public maxBidFullWatch: number;
	public clickUrl: string;
	public marginAssetPath: string;
	public bannerAssetPath: string;
	public videoAssetPath: string;
	public keywords: string[];
	public bulkPrice: boolean;
	public bulkPriceExpiration?: any;
	public adCampaignType: AdCampaignType;
}
export class AdCampaignSummaryViewModel
{
	public id: number;
	public name: string;
	public active: boolean;
	public dailySpend: number;
	public maxBidImpression: number;
	public maxBidClick: number;
	public maxBidFullWatch: number;
	public clickUrl: string;
	public marginAssetPath: string;
	public bannerAssetPath: string;
	public videoAssetPath: string;
	public keywords: string[];
	public bulkPrice: boolean;
	public bulkPriceExpiration?: any;
	public adCampaignType: AdCampaignType;
	public impressionCout: number;
	public clickCount: number;
	public courseEventCount: number;
	public totalSpend: number;
	public totalRevenue: number;
}
export class CampaignUser
{
	public id: number;
	public conferenceId: number;
	public conferenceSlug: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public fullNameWithEmail: string;
	public biography: string;
	public avatar: string;
	public referralToken: any;
	public exhibitName: string;
	public exhibitNumbers: string;
	public conferenceName: string;
	public conferenceStartDate: string;
	public purchasedPackageName: string;
	public sponsorOrganizationId?: number;
	public sponsorOrganizationName: string;
	public sponsorPortalLink: string;
	public referralLink: string;
	public sponsorLandingPageEditLink: string;
	public hotelReservationLink: string;
	public presentations: CampaignPresentation[];
	public sponsorOrganizationIdFromExhibit?: number;
	public sponsorOrganizationNameFromExhibit: string;
	public sponsorOrganizationIdFromOrder?: number;
	public sponsorOrganizationNameFromOrder: string;
	public hotelReservationConfirmationNumber: string;
	public checkinDate: string;
	public checkoutDate: string;
}
export class CampaignExhibit
{
	public userId: number;
	public exhibitName: string;
	public exhibitNumbers: string;
}
export class CampaignPresentation
{
	public id: number;
	public title: string;
	public startDate: any;
	public endDate: any;
	public formattedStartTime: string;
	public formattedDateWithTime: string;
	public formattedFullTime: string;
	public roomName: string;
	public description: string;
	public conferenceSlug: string;
	public presentationLink: string;
}
export class CampaignListSearchParams
{
	public existingUsers: number[];
	public listIds: number[];
	public searchText: string;
}
export class CampaignListResult
{
	public data: CampaignUser[];
	public listSize: number;
}
export class CampaignFilter
{
	public orFilters: AndFilters[];
}
export class AndFilters
{
	public atLeastOnePropertySet: boolean;
	public isRegistered?: boolean;
	public isRegisteredSinceDate?: any;
	public isRegisteredOnline?: boolean;
	public isRegisteredOnSite?: boolean;
	public purchasedAddonId?: number;
	public didCheckInToConference?: boolean;
	public didPurchaseCEUs?: boolean;
	public purchasedPackageId?: number;
	public purchasedEventId?: number;
	public earnedCEUEventId?: number;
	public scannedEventId?: number;
	public attendedVirtuallyEventId?: number;
	public didOpenEmail: LongFilterOperator;
	public respondedToSurvey: LongFilterOperator;
	public hasSponsorshipOrder?: boolean;
	public hasSponsorshipOrderSinceDate?: any;
	public hasAssignedExhibit?: boolean;
	public isAdminOfSponsorOrganization?: boolean;
	public hasExhibitOrder?: boolean;
	public hasNonExhibitOrder?: boolean;
	public sponsorshipOrderIsPaid?: boolean;
	public hasSponsorLandingPage?: boolean;
	public isExhibitScanner?: boolean;
	public isPresenter?: boolean;
	public isPresenterForEventId?: number;
	public isPresenterAndMissingBio?: boolean;
	public isPresenterAndMissingAbstract?: boolean;
	public isPresenterAndMissingHeadshot?: boolean;
	public isPresenterMissingCEUQuestions?: boolean;
	public isPresentingInRoom?: number;
	public isPresenterWithExpenseTracking?: boolean;
	public isVIPPresenter?: boolean;
	public submittedPaperSinceDate?: any;
	public hasPaperWithSatus?: PaperApprovalStatus;
	public hasPaperTypeId?: number;
	public hasPaperSubmission?: boolean;
	public isConferencePaperReviewer?: boolean;
	public isCEUScanAdmin?: boolean;
	public isPersonWithGuestRoomTracking?: boolean;
	public hasSubmittedGuestRoom?: boolean;
	public hasHotelReservation?: boolean;
}
export class FilterProperty
{
	public name: string;
	public description: string;
	public category: string;
	public type: string;
	public pickerType: string;
}
export class LongFilterOperator
{
	public operator: FilterOperator;
	public value: number;
}
export class IntegrationFieldConfig
{
	public integrationType: OrganizationIntegrationType;
	public clientIdName: string;
	public clientSecretName: string;
	public externalIdName: string;
	public description: string;
	public placeholder: string;
	public urlSuffix: string;
	public customField1: string;
}
export class CreateCourseViewModel
{
	public title: string;
	public tags: string[];
	public packageIds: number[];
	public marketingSplashPath: string;
	public ogSplashPath: string;
	public ogTwitterSplashPath: string;
	public featured: boolean;
	public restrictToOrganizationId?: number;
	public conferenceId?: number;
	public price?: number;
	public memberPrice?: number;
	public memberPricing: boolean;
	public currencyCode: string;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public summary: string;
	public description: string;
	public duration?: any;
	public azureAssetId: string;
	public courseEventStatus: CourseEventStatus;
	public instructor: InstructorViewModel;
	public coInstructors: InstructorViewModel[];
	public ceus: CEUViewModel[];
}
export class PaperReviewModel
{
	public score: number;
	public feedback: string;
	public userId: number;
	public createdDate: any;
	public user: InstructorViewModel;
}
export class ComposeEmailViewModel
{
	public to: InstructorViewModel[];
	public cc: InstructorViewModel[];
	public bcc: InstructorViewModel[];
	public subject: string;
	public body: string;
}
export class LinkSponsorOrderToSponsorOrganizationModel
{
	public existingSponsorOrganization?: number;
	public companyName: string;
}
export class ConferenceAnnouncementViewModel
{
	public id: number;
	public title: string;
	public fullTextBody: string;
	public previewText: string;
	public createdBy: UserViewModel;
	public createdDate: any;
	public sendPushNotification: boolean;
}
export class SponsorshipProductAddOnViewModel
{
	public id: number;
	public title: string;
	public description: string;
	public totalQuantity: number;
	public remainingQuantity: number;
}
export class SponsorshipProductTagViewModel
{
	public id: number;
	public tag: string;
}
export class SponsorshipProductViewModel
{
	public id: number;
	public title: string;
	public description: string;
	public shortDescription: string;
	public tags: string[];
	public marketingSplash: string;
	public totalQuantity: number;
	public remainingQuantity: number;
	public isBooth: boolean;
	public isSubProduct: boolean;
	public price: number;
	public isPrivate: boolean;
	public exhibitType?: ExhibitType;
	public requiredAssets: SponsorshipRequiredAssetViewModel[];
	public vouchers: SponsorshipVoucherViewModel[];
	public addOns: SponsorshipProductAddOnViewModel[];
	public addOnQuantity: number;
	public leadRetrieval: boolean;
	public selected: boolean;
}
export class SponsorshipRequiredAssetViewModel
{
	public id?: number;
	public assetType: AssetType;
	public name: string;
	public deadline: any;
	public description: string;
	public attachment: string;
	public widthInPx?: number;
	public heightInPx?: number;
}
export class SponsorshipVoucherViewModel
{
	public id?: number;
	public voucherType: VoucherType;
	public name: string;
	public description: string;
	public qty: number;
}
export class CourseEventEmailViewModel
{
	public subject: string;
	public body: string;
}
export class ConferenceCheckinSettingsViewModel
{
	public id: number;
	public name: string;
	public slug: string;
	public checkInBGSplash: string;
	public checkInFGSplash: string;
	public registrationOpen: boolean;
	public badgePrinterComputerId?: number;
}
export class ConferenceRegistrationSettingsViewModel
{
	public id: number;
	public packageEmailText: string;
	public inPersonLimit: number;
}
export class OrganizationIntegrationViewModel
{
	public id: number;
	public clientId: string;
	public clientSecret: string;
	public externalId: string;
	public customField1: string;
	public organizationIntegrationType: OrganizationIntegrationType;
	public events: ConferenceIntegrationEvent[];
	public conferenceIntegrations: OrganizationConferenceIntegrationViewModel;
}
export class OrganizationConferenceIntegrationViewModel
{
	public id?: number;
	public eventTitle: string;
	public packageIdToLink: number;
	public active: boolean;
	public packages: OrganizationConferenceIntegrationPackageViewModel[];
}
export class OrganizationConferenceIntegrationPackageViewModel
{
	public packageId: number;
	public packageName: string;
	public externalId: string;
}
export class GabaSurveyModel
{
	public gender: string;
	public sexualOrientation: string;
	public age: string;
	public race: string[];
	public primaryActivity: string;
	public region: string[];
	public contentInterest: string[];
	public events: string[];
}
export class BadgePrinterViewModel
{
	public id: number;
	public printNodeId: number;
	public description: string;
	public default: boolean;
	public roundRobinId: number;
}
export class OrganizationViewModelSimple
{
	public id: number;
	public name: string;
	public shortName: string;
	public slug: string;
}
export class CreatePaymentMethodViewModel
{
	public stripeToken: string;
	public last4: string;
	public cardType: string;
}
export class StripePriceViewModel
{
	public id: string;
	public currency: string;
	public unitAmount?: number;
	public active: boolean;
	public interval: string;
	public recurring: boolean;
	public usageType: string;
	public productName: string;
	public qty: number;
	public productId: string;
}
export class CreateSubscriptionViewModel
{
	public email: string;
	public allowCreditCards: boolean;
	public items: StripePriceViewModel[];
}
export class OrganizationSubscriptionViewModel
{
	public items: OrganizationSubscriptionItemViewModel[];
	public currentPeriodStart: any;
	public currentPeriodEnd: any;
	public id: string;
	public startDate: any;
}
export class OrganizationSubscriptionItemViewModel
{
	public id: string;
	public productId: string;
	public productName: string;
	public qty: number;
	public unitAmount?: number;
	public interval: string;
	public recurring: boolean;
	public usageType: string;
}
export class WowzaChannelViewModel
{
	public name: string;
	public state: WowzaState;
	public checkedForMultiview: boolean;
	public ingestUrl: string;
	public streamKey: string;
	public id: string;
	public rtmpUplink: string;
	public webRTCUplink: string;
	public previewUrl: string;
}
export class OrganizationInvoiceViewModel
{
	public id: string;
	public invoicePdf: string;
	public status: string;
	public amountDue: number;
	public amountPaid: number;
	public amountRemaining: number;
	public createdDate: any;
	public total: number;
	public hostedInvoiceUrl: string;
	public organizationName: string;
}
export class StripeProductViewModel
{
	public id: string;
	public name: string;
	public description: string;
	public active: boolean;
	public price?: number;
	public productId: string;
	public recurring: boolean;
	public interval: string;
	public prices: StripePriceViewModel[];
}
export class OrganizationViewModel
{
	public id: number;
	public name: string;
	public domainName: string;
	public shortName: string;
	public cardBrand: string;
	public headerColor: string;
	public landingIsEventsPage: boolean;
	public linkColor: string;
	public linkColorHover: string;
	public googleAnalyticsId: string;
	public breadCrumbColor: string;
	public stripeLinked: boolean;
	public breadCrumbColorActive: string;
	public slug: string;
	public logoUrl: string;
	public public: boolean;
	public isConference: boolean;
	public membershipFeatureFlag: boolean;
	public headerColorBottom: string;
	public navIconUrl: string;
	public ogSplashPath: string;
	public ogTwitterSplashPath: string;
	public ceuCertPdf: string;
	public hasCardOnFile: boolean;
	public hasSubscription: boolean;
	public paymentMethodLast4: string;
	public bacbProviderNumber: string;
	public mhpsyProviderNumber: string;
	public stripeConnectedAccountId: string;
	public packageContractPrice: number;
	public individualContractPercentage: number;
	public organizationRole: OrganizationRole;
	public adminClaims: string[];
	public enableOnDemandPayouts: boolean;
	public navLight: boolean;
	public navBGColor: string;
	public navActiveColor: string;
	public navHeroPadding: string;
	public navBannerHtml: string;
	public homePageHeroBGAsset: string;
	public homePageHeroFGAsset: string;
	public navActiveTextColor: string;
	public navTextColor: string;
	public liveEventPlatformFeePerRegistrant: number;
	public bundlePercentage: number;
	public accountManager: InstructorViewModel;
	public certifications: OrganizationCertificationViewModel[];
}
export class RegistrantDetailsViewModel
{
	public purchase: UserPackagePurchaseHistoryViewModel;
	public courseEvents: UserPurchaseHistoryViewModel[];
	public eventAddons: UserPurchaseHistoryViewModel[];
	public package: PackageViewModel;
	public promoCode: PromoCodeResultViewModel;
}
export class ConferenceBadgeElementViewModel
{
	public id: number;
	public horizontalAlignment: HorizontalAlignment;
	public badgeElementType: BadgeElementType;
	public text: string;
	public font: string;
	public fontSize: number;
	public fontStyle: FontStyle;
	public color: string;
	public x: number;
	public y: number;
	public width?: number;
	public height?: number;
	public zIndex: number;
	public assetPath: string;
	public order: number;
	public appendPreviousMargin?: number;
}
export class BadgeTemplateViewModel
{
	public id: number;
	public conferenceId: number;
	public name: string;
	public bgAssetPath: string;
	public badgeElements: ConferenceBadgeElementViewModel[];
}
export class PackageAddonPurchaseViewModel
{
	public addonId: number;
	public addonName: string;
	public addonPrice: number;
	public selected: boolean;
}
export class PackageAddonViewModel
{
	public id: number;
	public name: string;
	public description: string;
	public badgeCode: string;
	public price: number;
	public nonMemberPrice: number;
	public memberPricing: boolean;
	public currencyCode: string;
	public selected: boolean;
	public registrationLimit: number;
}
export class AddOnPurchaseViewModel
{
	public addOnId: number;
}
export class ExternalConfernceRegistration
{
	public firstName: string;
	public lastName: string;
	public emails: string[];
	public packageId?: number;
	public ceUsPaid: boolean;
}
export class LegacyExternalConfernceRegistration
{
	public firstName: string;
	public lastName: string;
	public email: string;
	public packageId?: number;
}
export class ScheduleUserEventViewModel
{
	public firstName: string;
	public lastName: string;
	public email: string;
	public alternateEmail: string;
	public phoneNumber: string;
	public avatar: string;
	public certificantNumber: string;
	public biography: string;
	public credentials: string;
	public pronouns: string;
	public base64Image: string;
	public title: string;
	public startTime: any;
	public endTime: any;
	public timeZone: string;
	public price: number;
	public summary: string;
	public description: string;
	public inPersonOnly: boolean;
	public ceus: CEUViewModel[];
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
}
export class CreateEventViewModel
{
	public title: string;
	public tags: string[];
	public startTime: any;
	public endTime: any;
	public timeZone: string;
	public allowRecording: boolean;
	public courseEventStatus: CourseEventStatus;
	public restrictToOrganizationId?: number;
	public sourcePaperId?: number;
	public marketingSplashPath: string;
	public currencyCode: string;
	public ogSplashPath: string;
	public ogTwitterSplashPath: string;
	public sponsorOffAirImage: string;
	public sponsorBugImage: string;
	public isWebRTC: boolean;
	public price: number;
	public memberPrice?: number;
	public summary: string;
	public description: string;
	public streamingChannel: string;
	public instructor: InstructorViewModel;
	public room: ConferenceRoomViewModel;
	public coInstructors: InstructorViewModel[];
	public staffModerators: InstructorViewModel[];
	public ceus: CEUViewModel[];
	public conferenceId?: number;
	public packageIds: number[];
	public inPersonOnly: boolean;
	public packageOnly: boolean;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public memberPricing: boolean;
}
export class CredentialsViewModel
{
	public userName: string;
	public password: string;
}
export class UpdateEventFromScheduleViewModel
{
	public timeIn5MinBlocks: number;
	public roomId: number;
	public roomSpan: number;
	public dayIndex: number;
	public currentDay: number;
	public schedulerColor: string;
	public title: string;
	public durationInMins: number;
}
export class ConferenceBadgeViewModel
{
	public badgeAssetPath: string;
	public badgeElements: BadgeElementViewModel[];
}
export class BadgeElementViewModel
{
	public id: number;
	public badgeElementType: BadgeElementType;
	public text: string;
	public font: string;
	public color: string;
	public fontSize: number;
	public fontStyle: FontStyle;
	public horizontalAlignment: HorizontalAlignment;
	public x: number;
	public y: number;
	public width?: number;
	public height?: number;
	public zIndex: number;
	public assetPath: string;
}
export class SchedulerSettingsViewModel
{
	public endHour: number;
	public startHour: number;
	public startDate: any;
	public endDate: any;
	public timeZone: string;
	public events: ScheduledEventViewModel[];
	public numberOfDays: number;
	public currentDateFormatted: string;
	public dayLabels: string[];
	public allColors: string[];
	public organizationId: number;
	public conferenceId: number;
	public dayIndex: number;
}
export class ScheduledEventViewModel
{
	public id: number;
	public slug: string;
	public title: string;
	public startTime: any;
	public endTime: any;
	public startTimeInTimeZone: any;
	public endTimeInTimeZone: any;
	public timeZone: string;
	public durationInMins: number;
	public schedulerColor: string;
	public room: ConferenceRoomViewModel;
	public roomSpan: number;
	public xCoordinate: number;
	public yCoordinate: number;
	public timeIn5MinBlocks: number;
	public dropopedColNum: number;
	public durationIn5MinBlocks: number;
	public overlap: boolean;
	public allInstructors: InstructorViewModel[];
	public speakerOverlap: boolean;
	public contentArea: string;
	public paperType: string;
	public ceus: CEUViewModel[];
	public tags: string[];
}
export class CustomSurveyReturnModel
{
	public questionText: string;
	public reponses: string;
}
export class DeviceInstallation
{
	public installationId: string;
	public platform: string;
	public pushChannel: string;
	public tags: string[];
}
export class NotificationRequest
{
	public title: string;
	public subtitle: string;
	public body: string;
	public badge: number;
	public conferenceId?: number;
	public chatGroupId?: number;
	public isChatRoom?: boolean;
	public action: PushNotificationAction;
	public tags: string[];
	public GetIOSTemplate() : string
	{
		return null;
	}
	public GetAndroidTemplate() : string
	{
		return null;
	}
}
export class SuperUserPackageLinkViewModel
{
	public packageId: number;
	public amountPaid: number;
	public transactionId: string;
	public transactionDate?: any;
}
export class SuperUserCourseEventLinkViewModel
{
	public courseEventId: number;
	public amountPaid: number;
	public transactionId: string;
	public transactionDate?: any;
}
export class PollViewModel
{
	public id: number;
	public eventId: number;
	public questionText: string;
	public visible: boolean;
	public options: PollOptionViewModel[];
	public totalResponses: number;
}
export class PollOptionViewModel
{
	public id: number;
	public pollId: number;
	public optionText: string;
	public responseCount: number;
	public currentUserChoice: boolean;
	public poll: PollViewModel;
	public totalResponses: number;
}
export class CreatePollViewModel
{
	public questionText: string;
	public visible: boolean;
	public options: CreatePollOptionViewModel[];
}
export class CreatePollOptionViewModel
{
	public optionText: string;
}
export class UpdatePollViewModel
{
	public questionText: string;
	public visible: boolean;
	public options: UpdatePollOptionViewModel[];
}
export class UpdatePollOptionViewModel
{
	public id: number;
	public optionText: string;
}
export class EventDashboardViewModel
{
	public channelStatus: string;
	public isChannelRunning: boolean;
	public broadcastStatus: string;
	public isBroadcastRunning: boolean;
	public isIngesting: boolean;
	public streamingUrl: string;
	public previewUrl: string;
	public ingestUrl: string;
	public streamKey: string;
}
export class EventRegistrationModel
{
	public email: string;
	public password: string;
	public firstName: string;
	public lastName: string;
	public creditCardNumber: string;
	public cvv: string;
	public month?: number;
	public year?: number;
}
export class GroupRegistrantViewModel
{
	public firstName: string;
	public lastName: string;
	public email: string;
}
export class GroupPurchaseViewModel
{
	public cardToken: string;
	public isCreditCard: boolean;
	public registrants: GroupRegistrantViewModel[];
	public receiptEmail: string;
	public poNumber: string;
	public name: string;
	public promoCode: string;
	public isMember: boolean;
	public purchaseCEUs: boolean;
	public selectedAddOns: PackageAddonViewModel[];
	public selectedEventAddons: CourseEventViewModel[];
}
export class CardTokenViewModel
{
	public cardToken: string;
	public optIn: boolean;
	public promoCode: string;
	public tShirtSize: string;
	public affiliation: string;
	public fullName: string;
	public memberPrice: boolean;
}
export class PurchasePackageViewModel
{
	public cardToken: string;
	public promoCode: string;
	public tShirtSize: string;
	public affiliation: string;
	public fullName: string;
	public pronouns: string;
	public selectedAddOns: PackageAddonViewModel[];
	public selectedEventAddons: CourseEventViewModel[];
	public customQuestionResponses: CustomRegistrationFieldViewModel[];
	public reservedBreakouts: number[];
	public isMember: boolean;
	public purchaseCEUs: boolean;
	public customSurveyJSON: string;
	public memberLookupResult: MemberLookupResult;
}
export class ModifyRegistrationViewModel
{
	public cardToken: string;
	public selectedAddOns: PackageAddonViewModel[];
	public selectedEventAddons: CourseEventViewModel[];
	public isMember: boolean;
	public purchaseCEUs: boolean;
	public reservedBreakouts: number[];
}
export class TipViewModel
{
	public cardToken: string;
	public amount: number;
}
export class ImageViewModel
{
	public blobPath: string;
	public base64File: string;
	public isPrimary: boolean;
}
export class CompleteEventViewModel
{
	public ceuToken1: string;
	public ceuToken2: string;
	public ceuToken3: string;
	public ceuToken4: string;
}
export class WebsiteSettings
{
	public id: number;
	public name: string;
	public slug: string;
	public navActiveColor: string;
	public navBGColor: string;
	public navHeroPadding: string;
	public navLight: boolean;
	public homePageHeroBGAsset: string;
	public homePageHeroFGAsset: string;
	public navActiveTextColor: string;
	public navTextColor: string;
}
export class ConferenceCEUScannerViewModel
{
	public userId: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public conference: ConferenceViewModelSimple;
	public lockInOut?: InOut;
	public ceuScanMode: CEUScanMode;
	public lockToRoom: ConferenceRoomViewModel;
}
export class ConferencePaperReviewerViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public fullName: string;
	public email: string;
	public isBlind: boolean;
	public assignedReviews: number;
	public completedReviews: number;
	public conference: ConferenceViewModel;
}
export class ExhibitCEUScannerViewModel
{
	public userId: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public sponsorOrganizationName: string;
	public sponsorOrganizationId: number;
	public conference: ConferenceViewModelSimple;
}
export class AssetMetadataViewModel
{
	public assetPath: string;
	public filename: string;
	public fileType: string;
}
export class SponsorshipLandingPageViewModel
{
	public conferenceId: number;
	public conferenceName: string;
	public sponsorOrganizationId: number;
	public sponsorshipLogo: string;
	public website: string;
	public exhibitNumber: string;
	public title: string;
	public subtitle: string;
	public published: boolean;
	public body: string;
	public currentUserDidScan: boolean;
	public isExhibit: boolean;
	public sortOrder: number;
}
export class ViewSponsorshipVoucherViewModel
{
	public id: number;
	public voucherType: VoucherType;
	public sponsorshipProduct: SponsorshipProductViewModel;
	public name: string;
	public description: string;
	public qty: number;
	public claimed: number;
	public claimedUsers: UserViewModel[];
	public lineItemId: number;
}
export class VourcherSearchParams
{
	public voucherType?: VoucherType;
}
export class OnDemandReportViewModel
{
	public courseEventTitle: string;
	public paid: boolean;
	public amount?: number;
	public createdDate: any;
	public ledgerEntryType: LedgerEntryType;
	public conference: string;
}
export class AttendanceReportViewModel
{
	public courseEventTitle: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public ceuParticipationDate?: any;
	public ceUsEarned: number;
	public metCEUCriteria: boolean;
	public participationModality: ParticipationModality;
}
export class SponsorshipVoucherItemViewModel
{
	public firstName: string;
	public lastName: string;
	public email: string;
	public organization: string;
	public exhibitId: string;
	public voucherName: string;
	public voucherType: VoucherType;
	public createdDate: any;
	public processed: boolean;
	public id: number;
}
export class SponsorshipAssetListingViewModel
{
	public organizationName: string;
	public organizationId: number;
	public requiredAssets: SponsorshipAssetViewModel[];
}
export class SponsorshipAssetViewModel
{
	public id: number;
	public sponsorshipProduct: SponsorshipProductViewModel;
	public assetType: AssetType;
	public name: string;
	public deadline: any;
	public description: string;
	public widthInPx?: number;
	public heightInPx?: number;
	public attachment: string;
	public assetPath: string;
	public fileName: string;
	public mimeType: string;
	public fileType: string;
	public allowedFileTypes: string[];
}
export class SponsorDashboardViewModel
{
	public daysRemaining: number;
	public registrants: number;
	public taskCount: number;
	public deadlines: SponsorDeadlineViewModel[];
	public tasks: SponsorTaskViewModel[];
}
export class SponsorTaskViewModel
{
	public name: string;
	public description: string;
	public completed: boolean;
}
export class SponsorDeadlineViewModel
{
	public id: number;
	public name: string;
	public deadline: any;
	public description: string;
}
export class OrganizationCertificationViewModel
{
	public id: number;
	public certificationBodyId: number;
	public certificationBody: CertificationBodyViewModel;
	public ceuProviderCode: string;
	public ceuProviderName: string;
	public ceuCoordinatorName: string;
	public notes: string;
	public disclaimer: string;
	public attendeeStatement: string;
	public headerImageAssetPath: string;
	public footerImageAssetPath: string;
	public validityPeriodStart?: any;
	public validityPeriodEnd?: any;
	public autoApprove: boolean;
	public userHasGenerated: boolean;
}
export class AuthenticatedDomainViewModel
{
	public id: number;
	public domain: string;
	public verified: boolean;
}
export class SponsorshipExhibitViewModel
{
	public id: number;
	public orderId: number;
	public product: SponsorshipProductViewModel;
	public variation: string;
	public variationId?: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public companyName: string;
}
export class SponsorshipOrderItemViewModel
{
	public id: number;
	public orderId: number;
	public orderTotal: number;
	public transactionId: string;
	public quantity: number;
	public unitPrice: number;
	public product: SponsorshipProductViewModel;
	public variation: string;
}
export class ConferenceViewModelSimple
{
	public id: number;
	public organizationId: number;
	public organization: OrganizationViewModelSimple;
	public name: string;
	public slug: string;
	public shortName: string;
	public timeZone: string;
	public ceuScanAdvertisementAsset: string;
	public showPosters: boolean;
	public startDate: any;
	public endDate: any;
	public ceuCount: number;
	public ceuCountOverride?: number;
	public marketingSplashPath: string;
	public city: string;
	public state: string;
}
export class ConferenceViewModeMobileApp
{
	public id: number;
	public organizationId: number;
	public startDate: any;
	public organization: OrganizationViewModel;
	public name: string;
	public slug: string;
	public shortName: string;
	public timeZone: string;
	public ceuScanAdvertisementAsset: string;
	public ceuScanAdvertisementUrl: string;
	public mobileLandingPageAdvertisementAsset: string;
	public mobileLandingPageCEUScanAdvertisementUrl: string;
	public mobileTopBarColor1: string;
	public mobileTopBarColor2: string;
	public mobileBottomBarColor: string;
	public mobileBottomBarTextColor: string;
	public mobileBottomBarTextColorSelcted: string;
	public generalTabBarBackgroundColor: string;
	public generalTabBarTextColor: string;
	public marketingSplashPath: string;
	public currentUserIsAdmin: boolean;
	public mobileTiles: ConferenceMobileTileViewModel[];
}
export class ConferenceMobileTileViewModel
{
	public id: number;
	public tileTitle: string;
	public navBarTitle: string;
	public backgroundColor: string;
	public textColor: string;
	public icon: string;
	public data: string;
	public conferenceMobileTileType: ConferenceMobileTileType;
	public conferenceMobileTileTypeDesigner: ConferenceMobileTileType;
	public order: number;
}
export class ScanDataViewModel
{
	public courseEventId: number;
	public checkInTime?: any;
	public checkOutTime?: any;
	public checkInValid: boolean;
	public checkOutValid: boolean;
}
export class QuizAnswerViewModel
{
	public responses: QuizResponseViewModel[];
}
export class QuizResponseViewModel
{
	public questionId: number;
	public answerId: number;
}
export class CEUPricingViewModel
{
	public price: number;
	public paid: boolean;
	public certificationBody: CertificationBodyViewModel;
	public currencyCode: string;
}
export class PurchaseCEUsViewModel
{
	public cardToken: string;
}
export class ConferenceCertificateViewModel
{
	public certification: OrganizationCertificationViewModel;
	public paidForCEUs: boolean;
	public userCertification: UserCertificationViewModel;
	public certificatesReleased: boolean;
	public user: UserViewModel;
	public isValid: boolean;
	public events: CEUEventSummary[];
}
export class CEUEventSummary
{
	public id: number;
	public assessmentId?: number;
	public title: string;
	public ceus: CEUViewModel[];
	public instructor: SimpleInstructorViewModel;
	public coInstructors: SimpleInstructorViewModel[];
	public ceuParticipationDate?: any;
	public metCEUCriteria: boolean;
	public ceuFeedbackSubmitted: boolean;
	public numberOfTokensSet: number;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public participationModality: ParticipationModality;
	public ceuCheckins: ScanDataViewModel;
	public originalAirDate?: any;
	public originalAirDateEndTime?: any;
	public apaAssessment: boolean;
	public hasEval: boolean;
}
export class ConferenceWithCertificateViewModel
{
	public id: number;
	public name: string;
	public startDate: any;
	public endDate: any;
	public city: string;
	public state: string;
	public ceuCertificateReleased: boolean;
	public ceuTypes: string[];
}
export class ConferenceViewModel
{
	public id: number;
	public organizationId: number;
	public organization: OrganizationViewModel;
	public name: string;
	public slug: string;
	public shortName: string;
	public city: string;
	public numberOfDays: number;
	public dayLabels: string[];
	public state: string;
	public navBannerHtml: string;
	public timeZone: string;
	public callForPaperInstructions: string;
	public registrationOpen: boolean;
	public conferenceModality: ConferenceModality;
	public navLight: boolean;
	public navBGColor: string;
	public currentAmountOwed: number;
	public totalRevenue: number;
	public pricePerRegistrant: number;
	public platformPriceMinimum: number;
	public navActiveColor: string;
	public navTextColor: string;
	public navHeroPadding: string;
	public exhibitsOpen: boolean;
	public blowOutEvents: boolean;
	public abstractManagement: boolean;
	public allowPosters: boolean;
	public externalMemberLookup: boolean;
	public approvalTemplateSet: boolean;
	public rejectTemplateSet: boolean;
	public ceuCertificateReleased: boolean;
	public published: boolean;
	public ceuCount: number;
	public ceuCountOverride?: number;
	public showNetworkingSessions: boolean;
	public showPosters: boolean;
	public startDate: any;
	public endDate: any;
	public homePageText: string;
	public homePageHeroAsset: string;
	public homePageHeroFGAsset: string;
	public homePageHeroBGAsset: string;
	public marketingSplashPath: string;
	public sponsorCurrency: string;
	public ceuScanAdvertisementAsset: string;
	public onlineCount: number;
	public inPersonCount: number;
	public badgeSize: BadgeSize;
	public pages: PageViewModel[];
	public registrationCount: number;
	public paidForCEUs: boolean;
	public messagingFeatureFlag: boolean;
	public surveyFeatureFlag: boolean;
	public expenseTrackingFeatureFlag: boolean;
	public planningFeatureFlag: boolean;
	public checkInBGSplash: string;
	public checkInFGSplash: string;
	public tracks: number;
	public travelAssignments: InstructorViewModel[];
	public conferenceModerators: InstructorViewModel[];
}
export class OrderedIdModel
{
	public ids: number[];
}
export class PackageSummaryViewModel
{
}
export class AddPageViewModel
{
	public slug: string;
	public navText: string;
	public isExternalLink: boolean;
	public externalLink: string;
	public hidden: boolean;
}
export class MemberLookupResult
{
	public success: boolean;
	public message: string;
	public totalDiscounts: number;
	public discountPercentage: number;
	public companyId?: any;
	public organizationName: string;
	public discountApplied: boolean;
	public email: string;
	public firstName: string;
	public lastName: string;
	public userId: string;
	public remainingDiscounts: number;
}
export class PageViewModel
{
	public id: number;
	public slug: string;
	public navText: string;
	public containerClass: string;
	public backgroundColor: string;
	public backgroundImage: string;
	public isExternalLink: boolean;
	public externalLink: string;
	public locked: boolean;
	public hidden: boolean;
	public elements: PageElementViewModel[];
}
export class CEUPrintSheetParameters
{
	public qrStyle: boolean;
	public roomId?: number;
	public singlePage: boolean;
	public formatAsPDF: boolean;
	public conferenceId?: number;
	public eventId?: number;
	public specificDates: string[];
}
export class PageElementViewModel
{
	public id: number;
	public pageElementType: PageElementType;
	public order: number;
	public data: string;
}
export class ConferenceViewModelWithDetails
{
	public id: number;
	public organizationId: number;
	public organization: OrganizationViewModel;
	public pages: PageViewModel[];
	public paperAcknowledgements: PaperAcknowledgementViewModel[];
	public name: string;
	public slug: string;
	public shortName: string;
	public callForPaperInstructions: string;
	public city: string;
	public state: string;
	public navBannerHtml: string;
	public homePageHeroBGAsset: string;
	public homePageHeroFGAsset: string;
	public navBGColor: string;
	public sponsorCurrency: string;
	public navActiveColor: string;
	public navHeroPadding: string;
	public allowPosters: boolean;
	public navLight: boolean;
	public callForPapersOpen: boolean;
	public exhibitsOpen: boolean;
	public externalRegistration: boolean;
	public approvalTemplateSet: boolean;
	public rejectTemplateSet: boolean;
	public externalRegistrationLink: string;
	public showPosters: boolean;
	public showNetworkingSessions: boolean;
	public startDate: any;
	public endDate: any;
	public homePageText: string;
	public homePageHeroAsset: string;
	public courseEvents: CourseEventViewModel[];
	public packages: PackageViewModel[];
}
export class EmployeeSearchParams
{
	public currentPage: number;
	public pageSize: number;
	public searchText: string;
}
export class ConferenceRegistrantSummaryViewModel
{
	public users: ConferenceRegistrantViewModel[];
	public total?: number;
	public count: number;
	public inPerson: number;
	public online: number;
	public currentPage: number;
	public pageSize: number;
	public totalPages: number;
	public resultCount: number;
	public distinctResultCount: number;
}
export class CEUReconciliationViewModel
{
	public firstName: string;
	public lastName: string;
	public email: string;
	public title: string;
	public startTime?: any;
	public endTime?: any;
	public checkInTime?: any;
	public checkOutTime?: any;
	public processed: boolean;
	public issued: boolean;
	public approved: boolean;
	public purchased: boolean;
	public checkInThresholdMet: boolean;
	public checkOutThresholdMet: boolean;
	public courseEventId: number;
	public userId?: number;
}
export class ConferenceChatMessageViewModel
{
	public id: number;
	public createdDate: any;
	public user: InstructorViewModel;
	public message: string;
	public isIncoming: boolean;
}
export class ChatMembersViewModel
{
	public chatMembers: number[];
}
export class CreateChatroomViewModel
{
	public name: string;
	public description: string;
}
export class ConferenceChatViewModel
{
	public id: number;
	public lastMessageTimestamp: any;
	public lastMessage: string;
	public name: string;
	public isChatRoom: boolean;
	public active: boolean;
	public description: string;
	public chatMembers: InstructorViewModel[];
	public currentUserIsMember: boolean;
}
export class ConferenceConnectionViewModel
{
	public fromUser: InstructorViewModel;
	public toUser: InstructorViewModel;
	public connectionState: ConferenceConnectionState;
}
export class ConferenceRegistrantViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public certificantNumber: string;
	public amountPaid?: number;
	public package: string;
	public fullNameWithCredentials: string;
	public packageId: number;
	public searchText: string;
	public affiliation: string;
	public pronouns: string;
	public phoneNumber: string;
	public isSuspended: boolean;
	public nameForBadge: string;
	public badgeTemplateOverride: string;
	public transactionDate: any;
	public checkedForPrint: boolean;
	public hasBadge: boolean;
	public credentials: string;
	public connection: ConferenceConnectionViewModel;
	public user: InstructorViewModel;
	public rfidCode?: number;
}
export class BoothPriceOverrideViewModel
{
	public price: number;
	public sponsorshipProductId: number;
	public disabled: boolean;
}
export class ConferenceMapViewModel
{
	public booths: ConferenceMapBoothViewModel[];
	public xFootToPx: number;
	public yFootToPx: number;
	public mapHeight: number;
	public mapWidth: number;
	public mapAsset: string;
}
export class SponsorshipOrderModel
{
	public id: number;
	public items: SponsorshipCartModel[];
	public cardToken: string;
	public companyName: string;
	public phoneNumber: string;
	public promoCode: string;
	public isPurchaseOrder: boolean;
	public isAdminOrder: boolean;
	public sendInvoice: boolean;
	public discount?: number;
	public firstName: string;
	public lastName: string;
	public email: string;
}
export class SponsorshipCartModel
{
	public product: SponsorshipProductViewModel;
	public variationId?: number;
	public variationName: string;
	public qty: number;
	public selectedAddons: SponsorshipProductAddOnViewModel[];
	public errorMessage: string;
}
export class ConferenceMapBoothViewModel
{
	public id: number;
	public name: string;
	public mapTop: number;
	public mapLeft: number;
	public widthInFeet: number;
	public heightInFeet: number;
	public price?: number;
	public productName: string;
	public computedWidth: number;
	public computedHeight: number;
	public selected: boolean;
	public color: string;
	public booked: boolean;
	public isHorizontal: boolean;
	public rotationAngle: number;
	public product: SponsorshipProductViewModel;
	public productId?: number;
	public priceOverrides: BoothPriceOverrideViewModel[];
}
export class PackageDropdownViewModel
{
	public id: number;
	public name: string;
	public category: string;
	public currencyCode: string;
	public price: number;
	public archived: boolean;
	public onSite: boolean;
}
export class CreateUpdatePackageViewModel
{
	public id: number;
	public name: string;
	public conferenceBadgeTemplateId?: number;
	public category: string;
	public noCEUs: boolean;
	public currencyCode: string;
	public ceuOverride?: number;
	public price: number;
	public order: number;
	public reserveBreakouts: boolean;
	public onSite: boolean;
	public archived: boolean;
	public courseEventIds: number[];
	public requirement: PackageRequirement;
	public onlyShowForGroups: boolean;
	public nonMemberPrice?: number;
	public memberPricing: boolean;
	public ceUsAreExtra: boolean;
	public ceuPrice?: number;
	public ceuNonMemberPrice?: number;
	public addOns: PackageAddonViewModel[];
	public eventAddonIds: number[];
	public customQuestionSurveyId?: number;
}
export class RegistrationPackageViewModel
{
	public id: number;
	public name: string;
	public currencyCode: string;
	public category: string;
	public noCEUs: boolean;
	public onSite: boolean;
	public price: number;
	public onlyShowForGroups: boolean;
	public reserveBreakouts: boolean;
	public totalCEUs: CEUViewModel[];
	public askForTShirtSize: boolean;
	public ceuOverride?: number;
	public order: number;
	public requirement: PackageRequirement;
	public nonMemberPrice?: number;
	public memberPricing: boolean;
	public ceUsAreExtra: boolean;
	public ceuPrice?: number;
	public ceuNonMemberPrice?: number;
	public totalPresentations: number;
	public addOns: PackageAddonViewModel[];
	public eventAddons: CourseEventViewModel[];
	public courseEvents: BasicEventViewModel[];
	public customRegistrationFields: CustomRegistrationFieldViewModel[];
	public description: string;
	public customQuestionSurveyId?: number;
	public eventAddonDiscountQuantityThreshold?: number;
	public eventAddonDiscountAmount?: number;
	public organizationId: number;
}
export class CustomRegistrationFieldOptionViewModel
{
	public id: number;
	public optionText: string;
}
export class CustomRegistrationFieldViewModel
{
	public id: number;
	public questionText: string;
	public placeholderText: string;
	public required: boolean;
	public responseText: string;
	public responseOption?: number;
	public fieldType: RegistrationFieldType;
	public options: CustomRegistrationFieldOptionViewModel[];
}
export class BasicEventViewModel
{
	public id: number;
	public title: string;
	public instructorName: string;
	public startTime: any;
	public formattedFullTime: string;
	public breakoutCapacity: number;
	public currentBreakoutCount: number;
	public tags: string[];
	public ceus: CEUViewModel[];
	public description: string;
	public allInstructors: string[];
}
export class PackageViewModel
{
	public id: number;
	public name: string;
	public description: string;
	public currencyCode: string;
	public category: string;
	public noCEUs: boolean;
	public onSite: boolean;
	public price: number;
	public onlyShowForGroups: boolean;
	public reserveBreakouts: boolean;
	public ceus: CEUViewModel[];
	public askForTShirtSize: boolean;
	public ceuOverride?: number;
	public order: number;
	public courseEvents: CourseEventViewModel[];
	public nonMemberPrice?: number;
	public memberPricing: boolean;
	public ceUsAreExtra: boolean;
	public ceuPrice?: number;
	public ceuNonMemberPrice?: number;
	public eventAddonDiscountQuantityThreshold?: number;
	public eventAddonDiscountAmount?: number;
	public addOns: PackageAddonViewModel[];
	public eventAddons: CourseEventViewModel[];
}
export class EventVideoViewModel
{
	public id: number;
	public title: string;
	public assetPath: string;
	public internal: boolean;
	public encoded: boolean;
	public playing: boolean;
	public visible: boolean;
}
export class StreamboxViewModel
{
	public id: number;
	public roomName: string;
	public azureChannel: string;
	public broadcastId: string;
	public openTokSessionId: string;
	public openTokCaptionSessionId: string;
	public captionId: string;
	public bgAssetPath: string;
	public selectedDevice: string;
}
export class StreamBoxLayoutViewModel
{
	public cameraWidth: number;
	public cameraHeight: number;
	public cameraTop: number;
	public cameraLeft: number;
	public cameraOnAir: boolean;
	public computerWidth: number;
	public computerHeight: number;
	public computerTop: number;
	public computerLeft: number;
	public computerOnAir: boolean;
}
export class MobileDeviceInfo
{
	public deviceType: string;
	public idiom: string;
	public manufacturer: string;
	public model: string;
	public name: string;
	public platform: string;
	public versionString: string;
	public appVersion: string;
}
export class EventDisplayViewModel
{
	public id: number;
	public slug: string;
	public title: string;
	public allowJoin: boolean;
	public memberPricing: boolean;
	public tags: string[];
	public startTime: any;
	public endTime: any;
	public instructor: SimpleInstructorViewModel;
	public coInstructors: SimpleInstructorViewModel[];
	public captureLinkedInProfile: boolean;
	public userHasPurchased: boolean;
	public packageOnly: boolean;
	public isExternal: boolean;
	public isExternalLink: string;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public organizationSlug: any;
	public inPersonOnly: boolean;
	public restrictToOrganizationId?: number;
	public organizationName: string;
	public conferenceId?: number;
	public conferenceName: string;
	public conferenceSlug: string;
	public optInLanguage: string;
	public showQuestions: boolean;
	public showTips: boolean;
	public showRequests: boolean;
	public price: number;
	public memberPrice: number;
	public currencyCode: string;
	public description: string;
	public allowRecording: boolean;
	public marketingSplashPath: string;
	public ceus: CEUViewModel[];
}
export class SimpleInstructorViewModel
{
	public id?: number;
	public title: string;
	public firstName: string;
	public lastName: string;
	public credentials: string;
	public fullNameWithCredentials: string;
	public fullName: string;
	public slug: string;
	public pronouns: string;
	public hidden: boolean;
}
export class ProgramExportTemplateViewModel
{
	public id: number;
	public name: string;
	public template: string;
	public order: number;
	public programRuleType: ProgramRuleType;
	public ruleValue: string;
	public conferenceId: number;
}
export class ProgramEventViewModelV2
{
	public formattedFullTime: string;
	public contentArea: string;
	public roomName: string;
	public ceUs: string;
	public title: string;
	public presenters: InstructorViewModel[];
	public summary: string;
	public hasLearningObjectives: boolean;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public paperType: string;
	public childPapers: ProgramChildPaper[];
	public discussant: InstructorViewModel;
	public chair: InstructorViewModel;
	public paperTypeId?: number;
	public tags: string[];
	public modifiedDate: any;
}
export class GenerateProgramViewModel
{
	public sinceDate?: any;
}
export class ProgramEventViewModel
{
	public title: string;
	public startTimeInTimeZone: any;
	public endTimeInTimeZone: any;
	public startTime: any;
	public endTime: any;
	public duration: any;
	public modifiedDate: any;
	public summary: string;
	public formattedFullTime: string;
	public formattedFullTimeRange: string;
	public description: string;
	public timeZone: string;
	public ceus: CEUViewModel[];
	public contentArea: string;
	public paperType: string;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public courseEventStatus: CourseEventStatus;
	public room: ConferenceRoomViewModel;
	public instructor: InstructorViewModel;
	public discussant: InstructorViewModel;
	public chair: InstructorViewModel;
	public coInstructors: InstructorViewModel[];
	public childPapers: ProgramChildPaper[];
	public modified: boolean;
}
export class ProgramChildPaper
{
	public title: string;
	public description: string;
	public presenter: InstructorViewModel;
	public authors: InstructorViewModel[];
}
export class CardReaderViewModel
{
	public reader: string;
	public mode: number;
	public room: string;
	public offset: number;
	public events: CardReaderEventViewModel[];
}
export class CardReaderDisplayViewModel
{
	public id: number;
	public readerId: string;
	public readerMode: ReaderMode;
	public conferenceRoomId: number;
}
export class ReaderScanViewModel
{
	public eventId: number;
	public readerMode: ReaderMode;
	public scans: ReaderScan[];
}
export class ReaderScan
{
	public timeStamp: any;
	public identifier: string;
}
export class CardReaderEventViewModel
{
	public id: number;
	public t: string;
	public st: any;
	public en: any;
	public fn: string;
	public ln: string;
	public hbd: number;
}
export class StripeBalanceViewModel
{
	public totalBalance: number;
	public wellsFargoBalance: number;
	public wealthFrontBalance: number;
}
export class TodaysEventViewmodel
{
	public id: number;
	public roomRoomName: string;
	public title: string;
	public startTime: any;
}
export class MobileAppEventListingViewModel
{
	public pagedEvents: any;
	public favorites: MobileEventViewModel[];
}
export class MobileInstructorViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public credentials: string;
	public avatar: string;
	public fullName: string;
	public fullNameWithCredentials: string;
}
export class MobileEventViewModel
{
	public id: number;
	public title: string;
	public description: string;
	public startTime: any;
	public endTime: any;
	public inPersonOnly: boolean;
	public marketingSplashPath: string;
	public instructor: MobileInstructorViewModel;
	public coInstructors: MobileInstructorViewModel[];
	public room: MobileConferenceRoomViewModel;
	public ceus: CEUViewModel[];
	public favorite: boolean;
	public userHasPurchased: boolean;
}
export class MobileConferenceRoomViewModel
{
	public roomName: string;
	public mapAssetPath: string;
}
export class MobileEventSummaryViewModel
{
	public id: number;
	public title: string;
	public instructors: string;
	public inPersonOnly: boolean;
	public marketingSplashPath: string;
	public startTime: any;
	public endTime: any;
	public roomName: string;
	public favorite: boolean;
	public userHasPurchased: boolean;
}
export class EventViewModel
{
	public id: number;
	public slug: string;
	public title: string;
	public tags: string[];
	public startTime: any;
	public endTime: any;
	public duration: any;
	public price: number;
	public memberPricing: boolean;
	public memberPrice: number;
	public currencyCode: string;
	public summary: string;
	public formattedFullTime: string;
	public description: string;
	public timeZone: string;
	public isBreakout: boolean;
	public breakoutCapacity: number;
	public currentBreakoutCount: number;
	public optInLanguage: string;
	public inPersonOnly: boolean;
	public captureLinkedInProfile: boolean;
	public restrictToOrganizationId?: number;
	public organizationName: string;
	public conferenceId?: number;
	public conferenceName: string;
	public conferenceBlowOutEvents: boolean;
	public allowRecording: boolean;
	public courseEventStatus: CourseEventStatus;
	public room: ConferenceRoomViewModel;
	public marketingSplashPath: string;
	public allowJoin: boolean;
	public isHybrid: boolean;
	public preRecorded: boolean;
	public packageOnly: boolean;
	public isExternal: boolean;
	public isExternalLink: string;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public standardCEUCount: number;
	public ethicsCEUCount: number;
	public supervisionCEUCount: number;
	public streamingStarted: boolean;
	public streamingFinished: boolean;
	public instructorCanEdit: boolean;
	public streamingChannel: string;
	public streamingFinishedTime: any;
	public streamingStartedTime: any;
	public ogSplashPath: string;
	public ogTwitterSplashPath: string;
	public sponsorOffAirImage: string;
	public sponsorBugImage: string;
	public isWebRTC: boolean;
	public allowAttendeesOnCamera: boolean;
	public maxAttendeesOnCamera: number;
	public packageIds: number[];
	public userHasPurchased: boolean;
	public showQuestions: boolean;
	public showTips: boolean;
	public disableChat: boolean;
	public showRequests: boolean;
	public failover: boolean;
	public ceus: CEUViewModel[];
	public instructor: InstructorViewModel;
	public coInstructors: InstructorViewModel[];
	public staffModerators: InstructorViewModel[];
	public organizationSlug: any;
	public favorite: boolean;
}
export class LiveEventViewModel extends EventViewModel
{
	public userWasInvited: boolean;
	public activeUsersMaxedOut: boolean;
	public maxOpenTokConnections: number;
}
export class LiveEventDashboardViewModel
{
	public chatHistory: ChatHistoryViewModel[];
	public questionHistory: QuestionHistoryViewModel[];
	public activeUsers: ActiveUserViewModel[];
}
export class TapBackViewModel
{
	public user: UserViewModel;
	public emoji: string;
	public id: number;
}
export class GroupedTapBackViewModel
{
	public users: string[];
	public emoji: string;
}
export class ChatHistoryViewModel
{
	public id: number;
	public createdDate: any;
	public user: UserViewModel;
	public message: string;
	public isEventLink: boolean;
	public eventLinkId: string;
	public tapBacks: GroupedTapBackViewModel[];
}
export class QuestionVoteViewModel
{
	public userId: number;
}
export class QuestionHistoryViewModel
{
	public id: number;
	public createdDate: any;
	public user: UserViewModel;
	public question: string;
	public answered: boolean;
	public selected: boolean;
	public currentUserDidVote: boolean;
	public questionVotes: QuestionVoteViewModel[];
}
export class QuestionHistoryTitlesViewModel
{
	public id: number;
	public createdDate: any;
	public fullName: string;
	public question: string;
	public answered: boolean;
	public votes: number;
	public avatar: string;
	public selected: boolean;
}
export class EventSummaryViewModel extends EventViewModel
{
	public attendeeCount: number;
	public reservationCount: number;
	public assessmentId?: number;
	public revenue: number;
	public openTokArchiveId: string;
	public openTokArchiveIdSecondary: string;
	public restrictToOrganizationName: string;
	public checkedForMultiview: boolean;
}
export class SponsorshipOrderViewModel
{
	public id: number;
	public companyName: string;
	public phoneNumber: string;
	public createdDate: any;
	public purchasingUser: UserViewModel;
	public orderTotal: number;
	public transactionId: string;
	public isPaid: boolean;
	public isPurchaseOrder: boolean;
	public sponsorOrganization: SponsorOrganizationViewModel;
	public items: SponsorshipOrderLineItemViewModel[];
	public assetsUploaded: boolean;
}
export class SponsorshipOrderLineItemViewModel
{
	public product: SponsorshipProductViewModel;
	public quantity: number;
	public unitPrice: number;
	public sponsorshipProductBoothId?: number;
	public sponsorshipProductBooth: ConferenceMapBoothViewModel;
	public selectedAddons: SponsorshipProductAddOnViewModel[];
}
export class ConferenceRoomViewModel
{
	public id: number;
	public conferenceId: number;
	public roomName: string;
	public subjectOrTrackName: string;
	public channel: string;
	public specificDates: string[];
	public notes: string;
	public classroomCapacity?: number;
	public roundTableCapacity?: number;
	public theaterCapacity?: number;
	public roomNumber?: number;
	public floorName: string;
	public mapAssetPath: string;
}
export class AzureBlobSASViewModel
{
	public containerName: string;
	public sasToken: string;
	public storageUri: string;
	public fileName: string;
	public uri: string;
}
export class AuthorCourseVewModel
{
	public title: string;
	public price: number;
	public summary: string;
	public description: string;
	public recordingMethod: RecordingMethod;
	public ceus: CEUViewModel[];
}
export class ConferenceCEUModel
{
	public conferenceName: string;
	public startDate: any;
	public endDate: any;
	public location: string;
	public organizationName: string;
	public credentialNumber: string;
	public shortName: string;
	public firstName: string;
	public lastName: string;
	public certificantNumber: string;
	public events: EventWithCEU[];
	public totalCE: number;
	public ethicsCE: number;
	public supervisionCE: number;
	public ceuCertificateReleased: boolean;
}
export class CEUScanHistory
{
	public courseEventId: number;
	public courseEventTitle: string;
	public checkInTime?: any;
	public checkOutTime?: any;
	public processed: boolean;
	public issued: boolean;
	public courseEventHasAssessment: boolean;
}
export class CEUHistory
{
	public courseEventId: number;
	public title: string;
	public standardCEUEarned: number;
	public ethicsCEUEarned: number;
	public supervisionCEUEarned: number;
	public slpceuEarned: number;
	public bbscpaceuEarned: number;
	public mhPsychCEUEarned: number;
	public apaceuEarned?: number;
	public apaceuApproved: boolean;
	public apaceuAssessmentDate?: any;
	public paidForCEUs: boolean;
	public completionDate?: any;
	public pending: boolean;
}
export class EventWithCEU extends CEUHistory
{
	public courseEvents: CourseEventViewModel;
}
export class EventSearchParameters
{
	public onlyShowFuture: boolean;
	public excludeIfConference: boolean;
	public conferenceId?: number;
}
export class PosterSessionViewModel
{
	public conferenceName: string;
	public id: number;
	public dateTime: any;
}
export class PosterAuthorViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public affiliation: string;
}
export class CreatePosterViewModel
{
	public description: string;
	public posterAssetPath: string;
	public posterThumbnailPath: string;
	public title: string;
	public website: string;
	public base64Image: string;
	public base64ImageThumb: string;
	public materials: MaterialViewModel[];
	public additionalAuthors: PosterAuthorViewModel[];
	public irbApproval: boolean;
	public affiliation: string;
}
export class PosterViewModel
{
	public id: number;
	public conferenceId: number;
	public posterSessionId: number;
	public posterSessionAllowJoin: boolean;
	public posterSessionPostersVisible: boolean;
	public posterSessionDateTime: any;
	public title: string;
	public website: string;
	public irbApproval: boolean;
	public approved: boolean;
	public description: string;
	public posterAssetPath: string;
	public posterThumbnailPath: string;
	public posterSessionConferenceName: string;
	public organizationSlug: string;
	public chatHistory: ChatHistoryViewModel[];
	public authors: PosterAuthorViewModel[];
	public materials: MaterialViewModel[];
}
export class ExhibitInviteViewModel
{
	public email: string;
}
export class ExhibitSummaryViewModel
{
	public id: number;
	public conferenceId: number;
	public title: string;
	public isHybrid: boolean;
	public conferenceStartDate: string;
	public conferenceEndDate: string;
	public conferenceName: string;
	public published: boolean;
	public order: number;
}
export class LobbyViewModel
{
	public id: number;
	public conferenceId: number;
	public title: string;
}
export class CreateUpdateExhibitViewModel
{
	public id: number;
	public conferenceId: number;
	public title: string;
	public isHybrid: boolean;
	public emailToInvite: string;
	public users: UserViewModel[];
}
export class ExhibitDesignerViewModel
{
	public id: number;
	public conferenceId: number;
	public title: string;
	public subtitle: string;
	public published: boolean;
	public isHybrid: boolean;
	public organizationSlug: string;
	public conferenceStartDate: string;
	public conferenceEndDate: string;
	public conferenceName: string;
	public linkToWebsite: boolean;
	public bannerAssetPath: string;
	public logoAssetPath: string;
	public introVideoAssetPath: string;
	public website: string;
	public description: string;
	public chatHistory: ChatHistoryViewModel[];
	public materials: MaterialViewModel[];
}
export class UpdateExhibitViewModel
{
	public description: string;
	public bannerAssetPath: string;
	public logoAssetPath: string;
	public website: string;
	public title: string;
	public isHybrid: boolean;
	public published: boolean;
	public subtitle: string;
	public bannerBase64Image: string;
	public logoBase64Image: string;
	public introVideoAssetPath: string;
	public materials: MaterialViewModel[];
}
export class CourseSearchResultsViewModel
{
	public resultCount: number;
	public page?: number;
	public courses: CourseViewModelSimple[];
}
export class SearchFilterDataModel
{
	public totalCount: number;
	public organizations: NameValuePair[];
	public conferences: NameValuePair[];
	public tags: NameValuePair[];
	public ceuTypes: NameValuePair[];
	public ceuCount: NameValuePair[];
	public speakerTypes: NameValuePair[];
}
export class ConferenceIntegrationEvent
{
	public id: string;
	public name: string;
}
export class NameValuePair
{
	public value: number;
	public name: string;
}
export class StrNameValuePair
{
	public value: string;
	public name: string;
}
export class CourseSearchParameters
{
	public query: string;
	public tag?: number;
	public certifyingBodyId?: number;
	public speakerType?: CourseSpeakerType;
	public ceuCount?: number;
	public organizationId?: number;
	public conferenceId?: number;
	public page?: number;
	public pageSize?: number;
	public completedCEUs?: boolean;
	public watched?: boolean;
}
export class CEUCheckinModel
{
	public eventId: number;
	public userId?: number;
	public temporaryIdentifier: string;
	public timeStamp: any;
	public inOut: InOut;
}
export class LogExhibitVisitModel
{
	public exhibitId: number;
	public userId?: number;
	public timeStamp: any;
}
export class SalesHistoryViewModel
{
	public courseEventTitle: string;
	public paid: boolean;
	public amount?: number;
	public createdDate: any;
}
export class ChangePasswordViewModel
{
	public currentPassword: string;
	public newPassword: string;
}
export class StreamHealthViewModel
{
	public id: number;
	public channel: string;
	public eventTime: any;
	public trackType: string;
	public trackName: string;
	public bitrate: number;
	public incomingBitrate: number;
	public lastTimestamp: number;
	public timescale: number;
	public overlapCount: number;
	public discontinuityCount: number;
	public nonincreasingCount: number;
	public unexpectedBitrate: boolean;
	public state: string;
	public healthy: boolean;
	public lastFragmentArrivalTime: string;
	public ingestDriftValue: string;
	public transcriptionState: string;
	public transcriptionLanguage: string;
}
export class CertificationBodyViewModel
{
	public id: number;
	public name: string;
	public shortName: string;
	public unitName: string;
	public baseTypeName: string;
	public subType1Name: string;
	public subType2Name: string;
	public subType3Name: string;
	public baseTypeAbbreviation: string;
	public subType1Abbreviation: string;
	public subType2Abbreviation: string;
	public subType3Abbreviation: string;
	public hasEval: boolean;
}
export class TagViewModel
{
	public id: number;
	public name: string;
	public showInNav: boolean;
	public showInSearch: boolean;
}
export class ReferralBonusViewModel
{
	public count: number;
	public sum?: number;
}
export class CreateReviewViewModel
{
	public text: string;
	public rating: number;
	public isMobile: boolean;
}
export class CourseReviewViewModel
{
	public id: number;
	public text: string;
	public authorId: string;
	public authorFirstName: string;
	public authorLastName: string;
	public authorAvatar: string;
	public rating: number;
	public createdDate: any;
}
export class SponsorStoreSettings
{
	public allowCreditCardsForSponsorships: boolean;
}
export class PromoCodeResultViewModel
{
	public id: number;
	public code: string;
	public discountPercentage?: number;
	public discountAmount?: number;
}
export class APAEvaluationViewModel
{
	public learningObjective1Met: number;
	public learningObjective2Met: number;
	public learningObjective3Met: number;
	public contentAppropriate: number;
	public teachingMethodsEffective: number;
	public visualAidsClarified: number;
	public clarityRequirements: number;
	public clarityCommercialSupport: number;
	public clarityResearchGrants: number;
	public clarityBooksTrainings: number;
	public clarityAccuracy: number;
	public learningCanBeApplied: number;
	public learningAcheivingPersonalGoals: number;
	public learningDiversity: number;
	public learningHowMuch: number;
	public learningHowUseful: number;
	public learningEnhancedProfessionalExpertise: number;
	public learningWouldRecommend: number;
	public professions: string[];
	public yearsInProfession: string;
	public comments: string;
}
export class CourseEventDropdownViewModel
{
	public id: number;
	public title: string;
	public slug: string;
	public inPersonOnly: boolean;
	public restrictToOrganizationName: string;
	public originalAirDate: any;
	public instructor: InstructorViewModel;
}
export class CourseEventViewModel
{
	public id: number;
	public title: string;
	public slug: string;
	public tags: string[];
	public duration?: any;
	public modifiedDate: any;
	public createdDate: any;
	public memberPricing: boolean;
	public price: number;
	public memberPrice?: number;
	public currencyCode: string;
	public formattedStartTime: string;
	public formattedFullTime: string;
	public originalAirDate?: any;
	public rating?: number;
	public reviews: number;
	public summary: string;
	public description: string;
	public restrictToOrganizationId?: number;
	public conferenceId?: number;
	public conferenceName: string;
	public marketingSplashPath: string;
	public discriminator: string;
	public ogSplashPath: string;
	public ogTwitterSplashPath: string;
	public courseEventStatus: CourseEventStatus;
	public released: boolean;
	public packageOnly: boolean;
	public trailerAssetId: string;
	public instructor: InstructorViewModel;
	public coInstructors: InstructorViewModel[];
	public segments: SegmentViewModel[];
	public materials: MaterialViewModel[];
	public packageIds: number[];
	public room: ConferenceRoomViewModel;
	public userHasPurchased: boolean;
	public startTime?: any;
	public endTime?: any;
	public timeZone: string;
	public allowJoin: boolean;
	public preRecorded: boolean;
	public streamingStarted: boolean;
	public streamingFinished: boolean;
	public streamingFinishedTime: any;
	public streamingStartedTime: any;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public isWebRTC: boolean;
	public selected: boolean;
	public ceus: CEUViewModel[];
}
export class PlayheadLocationModel
{
	public playheadLocation: number;
	public playheadLocationMax: number;
}
export class CourseViewModelSimple
{
	public id: number;
	public title: string;
	public slug: string;
	public conferenceName: string;
	public marketingSplashPath: string;
	public duration: any;
	public rating?: number;
	public reviews: number;
	public description: string;
	public instructor: SimpleInstructorViewModel;
	public coInstructors: SimpleInstructorViewModel[];
	public userHasPurchased: boolean;
	public assessmentId?: number;
	public price: number;
	public courseEventStatus: CourseEventStatus;
	public released: boolean;
	public currencyCode: string;
	public azureAssetId: string;
	public wowzaPlaybackUrl: string;
	public restrictToOrganizationId?: number;
	public ceus: CEUViewModel[];
	public standardCEUCount: number;
	public ethicsCEUCount: number;
	public supervisionCEUCount: number;
}
export class CourseEventSiteMapViewModel
{
	public slug: string;
	public modifiedDate: any;
}
export class JobSiteMapViewModel
{
	public id: number;
	public modifiedDate: any;
}
export class CourseViewModel
{
	public id: number;
	public title: string;
	public slug: string;
	public tags: string[];
	public featured: boolean;
	public vttCreated?: boolean;
	public duration: any;
	public modifiedDate: any;
	public createdDate: any;
	public memberPricing: boolean;
	public memberPrice: number;
	public price: number;
	public currencyCode: string;
	public rating?: number;
	public reviews: number;
	public summary: string;
	public description: string;
	public mp4StreamingLocatorName: string;
	public azureMp4DownloadPath: string;
	public wowzaMp4DownloadPath: string;
	public mp4Encoded?: boolean;
	public openTokArchiveId?: any;
	public openTokArchiveIdSecondary?: any;
	public streamingLocatorName: string;
	public encoded: boolean;
	public azureAssetId: string;
	public assessmentId?: number;
	public restrictToOrganizationId?: number;
	public restrictToOrganizationName: string;
	public conferenceId?: number;
	public conferenceName: string;
	public marketingSplashPath: string;
	public ogSplashPath: string;
	public learningObjective1: string;
	public learningObjective2: string;
	public learningObjective3: string;
	public ogTwitterSplashPath: string;
	public courseEventStatus: CourseEventStatus;
	public released: boolean;
	public packageOnly: boolean;
	public isExternal: boolean;
	public isExternalLink: string;
	public trailerAssetId: string;
	public instructor: InstructorViewModel;
	public coInstructors: InstructorViewModel[];
	public segments: SegmentViewModel[];
	public materials: MaterialViewModel[];
	public wowzaPlaybackUrl: string;
	public packageIds: number[];
	public userHasPurchased: boolean;
	public wowzaAssetId?: any;
	public ceus: CEUViewModel[];
	public organizationShortName: string;
}
export class UsersAuthoredEventsViewModel
{
	public id: number;
	public title: string;
	public price: number;
	public purchaseCount: number;
	public revenue: number;
	public startTime: any;
	public endTime: any;
	public timeZone: string;
	public duration: any;
	public courseEventStatus: CourseEventStatus;
	public conference: string;
	public instructorCanEdit: boolean;
	public marketingSplashPath: string;
	public description: string;
	public reservationCount: number;
	public roomName: string;
}
export class UsersAuthoredCoursesViewModel
{
	public id: number;
	public title: string;
	public price: number;
	public purchaseCount: number;
	public revenue: number;
	public courseEventStatus: CourseEventStatus;
	public conference: string;
	public organization: string;
}
export class CreateSegmentViewModel
{
	public id: number;
	public title: string;
	public number: number;
	public duration?: any;
	public azureAssetId: string;
	public azureAssetFilterName: string;
	public segmentType: SegmentType;
	public questions: SegmentQuestion[];
}
export class CreateAssessmentViewModel
{
	public id: number;
	public questions: SegmentQuestion[];
}
export class SegmentQuestion
{
	public text: string;
	public answers: SegmentAnswer[];
}
export class SegmentAnswer
{
	public text: string;
	public isCorrectResponse: boolean;
}
export class MergeSponsorOrganizationsViewModel
{
	public aOrganizationId: number;
	public bOrganizationId: number;
}
export class GeneralLedgerViewModel
{
	public id: number;
	public userId: number;
	public paid: boolean;
	public amount?: number;
	public stripeParentTxId: string;
	public stripeConnectedTxId: string;
	public ledgerEntryType: LedgerEntryType;
	public createdDate: any;
}
export class UpdateCEUModel
{
	public ceuParticipationDate?: any;
	public metCEUCriteria: boolean;
	public participationModality: ParticipationModality;
}
export class UserPurchaseHistoryViewModel
{
	public courseEventTitle: string;
	public courseEventId: number;
	public courseEventCurrencyCode: string;
	public amountPaid?: number;
	public transactionDate: any;
	public fromPackage: boolean;
	public transactionId: string;
	public completionDate?: any;
	public ceuToken1: string;
	public ceuToken2: string;
	public ceuToken3: string;
	public ceuToken4: string;
	public leadInstructor: string;
	public quizAttempts: number;
	public playheadLocationMax: number;
	public paidForCEUs: boolean;
	public ceuParticipationDate?: any;
	public metCEUCriteria: boolean;
	public ceuFeedbackSubmitted: boolean;
	public participationModality: ParticipationModality;
}
export class UserPackagePurchaseViewModel
{
	public userId: number;
	public packageId: number;
	public organizationId: number;
	public package: PackageViewModel;
	public amountPaid?: number;
	public transactionDate: any;
	public transactionId: string;
	public paidForCEUs: boolean;
	public memberRate: boolean;
	public addOns: AddOnPurchaseViewModel[];
	public reservations: ReservationViewModel[];
}
export class ReservationViewModel
{
	public courseEventId: number;
}
export class UserPackagePurchaseHistoryViewModel
{
	public userId: number;
	public packageId: number;
	public packageName: string;
	public packageCurrencyCode: string;
	public promoCode: string;
	public packageOnSite: boolean;
	public amountPaid?: number;
	public transactionDate: any;
	public transactionId: string;
	public packageConferenceName: string;
	public packageConferenceSlug: string;
	public packageConferenceOrganizationSlug: string;
	public packageConferenceId: string;
	public paidForCEUs: boolean;
	public purchaseOrderId: number;
	public memberRate: boolean;
	public user: InstructorViewModel;
	public courseEvents: UserPurchaseHistoryViewModel[];
	public addOns: PackageAddonPurchaseViewModel[];
}
export class CreateConferenceRegistrationViewModel
{
	public firstName: string;
	public lastName: string;
	public email: string;
	public nameForBadge: string;
	public affiliation: string;
	public packageId: number;
	public oldPackageId?: number;
}
export class UserPackageRegistrations
{
	public userFirstName: string;
	public userLastName: string;
	public userEmail: string;
	public packageId: number;
	public packageName: string;
	public packageCurrencyCode: string;
	public amountPaid?: number;
	public transactionDate: any;
	public transactionId: string;
}
export class RefundModel
{
	public removeRegistration: boolean;
	public amountToRefund?: number;
}
export class MaterialViewModel
{
	public id: number;
	public fileType: FileType;
	public description: string;
	public blobPath: string;
	public fileName: string;
}
export class UpdateContractViewModel
{
	public contractItems: ContractViewModel[];
}
export class ContractViewModel
{
	public userId: number;
	public user: UserViewModel;
	public description: string;
	public percentage: number;
}
export class RegistrantSearchParameters
{
	public isInstructor?: boolean;
	public startingLetter: string;
	public searchText: string;
	public packages: number[];
	public courseEventId?: number;
	public organizationId?: number;
	public modality?: RegistrationModality;
	public currentPage: number;
	public pageSize: number;
	public onePerPage: boolean;
	public specificUsers: number[];
	public addOns: number[];
	public eventAddons: number[];
	public registrantSort?: RegistrantSort;
}
export class ConferenceSpeakerSearchParameters
{
	public searchText: string;
	public vip?: boolean;
	public roomTracking?: boolean;
	public expenseTracking?: boolean;
	public registered?: boolean;
}
export class UserSearchParameters
{
	public isInstructor?: boolean;
	public startingLetter: string;
	public searchText: string;
	public courseEventId?: number;
	public attendeeCourseEventId?: number;
	public organizationId?: number;
	public currentPage: number;
	public pageSize: number;
}
export class SuperUserCourseSearchParameters
{
	public courseEventStatus?: CourseEventStatus;
	public orderBy?: OrderBy;
	public organizationId?: number;
	public conferenceId?: number;
	public searchText: string;
}
export class OrganizationCourseSearchParameters
{
	public courseEventStatus?: CourseEventStatus;
	public orderBy?: OrderBy;
	public page: number;
	public pageSize: number;
}
export class SuperUserEventSearchParameters
{
	public courseEventStatus?: CourseEventStatus;
	public orderBy?: EventOrderBy;
	public organizationId?: number;
	public isWebRTC?: boolean;
	public searchText: string;
	public pageSize: number;
}
export class OrganizationEventSearchParams
{
	public courseEventStatus?: CourseEventStatus;
	public orderBy?: EventOrderBy;
	public searchText: string;
	public tag: string;
	public isAdminSearch: boolean;
	public roomId?: number;
	public selectedDay: string;
	public selectedTime: string;
}
export class PurchaseOrderViewModel
{
	public id: number;
	public packageName: string;
	public conference: string;
	public name: string;
	public poNumber: string;
	public invoicePdf: string;
	public contactEmail: string;
	public amount: number;
	public stripeInvoiceId: string;
	public paid: boolean;
	public activated: boolean;
	public paidDate?: any;
	public activatedDate?: any;
	public createdDate: any;
	public userCount: number;
}
export class SegmentViewModel
{
	public id: number;
	public title: string;
	public number: number;
	public duration?: any;
	public azureAssetId: string;
	public azureAssetFilterName: string;
	public encoded: boolean;
	public segmentType: SegmentType;
	public assessment: AssessmentViewModel;
}
export class SegmentProgressViewModel extends SegmentViewModel
{
	public segmentStatus: SegmentStatus;
	public playheadLocation: number;
	public active: boolean;
	public latestQuizScore?: number;
	public quizAttempts: number;
}
export class CourseSummaryViewModel extends CourseViewModel
{
	public purchaseCount: number;
	public revenue: number;
}
export class PromoCodeViewModel
{
	public id: number;
	public code: string;
	public discountPercentage?: number;
	public discountAmount?: number;
	public specificCourses: boolean;
	public usageCount: number;
	public allowedUses: number;
	public packageIds: number[];
	public courseEventIds: number[];
}
export class CEUViewModel
{
	public certificationBodyId: number;
	public certificationBody: CertificationBodyViewModel;
	public ceuCount: number;
	public subType1CEUCount?: number;
	public subType2CEUCount?: number;
	public subType3CEUCount?: number;
}
export class ConferenceGuestRoomTypeViewModel
{
	public id: number;
	public name: string;
	public description: string;
	public rate: number;
	public public: boolean;
}
export class ConferenceSpeakerViewModel
{
	public user: InstructorViewModel;
	public conferenceName: string;
	public expenseTracking: boolean;
	public guestRoomTracking: boolean;
	public isVIP: boolean;
	public checkinDate?: any;
	public checkOutDate?: any;
	public roomType: ConferenceGuestRoomTypeViewModel;
	public confirmationNumber: string;
	public notes: string;
	public optedInForRoom?: boolean;
	public roomBillingMode: RoomBillingMode;
	public isRegistered: boolean;
	public expenses: ConferenceSpeakerExpenseViewModel[];
}
export class ConferenceSpeakerExpenseViewModel
{
	public id: number;
	public date: any;
	public description: string;
	public amount: number;
	public approvedAmount: number;
	public paid: boolean;
	public currency: string;
	public receiptImage: string;
	public createdById: number;
}
export class InstructorViewModel
{
	public id?: number;
	public title: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public credentials: string;
	public biography: string;
	public organizationalTitle: string;
	public avatar: string;
	public pronouns: string;
	public genderIdentity: string;
	public raceEthnicity: string;
	public phoneNumber: string;
	public certificantNumber: string;
	public modifiedDate: any;
	public slug: string;
	public affiliation: string;
	public featured: boolean;
	public emailVerified: boolean;
	public isBCBA?: boolean;
	public hidden: boolean;
	public stripeLinked: boolean;
	public fullName: string;
	public fullNameReverse: string;
	public fullNameWithCredentials: string;
	public hasAffiliation: boolean;
}
export class MergeInstructorViewModel
{
	public userAId?: number;
	public userBId?: number;
	public title: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public credentials: string;
	public biography: string;
	public avatar: string;
	public pronouns: string;
	public phoneNumber: string;
	public certificantNumber: string;
}
export class AssessmentViewModel
{
	public id: number;
	public questions: AssessmentQuestionsViewModel[];
}
export class AssessmentResponsesViewModel
{
	public text: string;
	public rating: number;
	public responses: AssessmentResponseViewModel[];
}
export class AssessmentResponseViewModel
{
	public questionId: number;
	public responseId?: number;
}
export class AssessmentQuestionsViewModel
{
	public id: number;
	public text: string;
	public answers: AssessmentAnswersViewModel[];
}
export class AssessmentAnswersViewModel
{
	public id: number;
	public assessmentQuestionId: number;
	public text: string;
	public selected: boolean;
}
export class InstructorSummaryViewModel extends InstructorViewModel
{
	public courseEventCount: number;
}
export class InstructorWithCoursesViewModel extends InstructorViewModel
{
	public courses: CourseViewModel[];
}
export class GhostWriterQuestion
{
	public questionText: string;
	public answers: GhostWriterAnseer[];
}
export class GhostWriterAnseer
{
	public answerText: string;
	public isCorrect: boolean;
}
export class CreateInstructorViewModel
{
	public title: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public credentials: string;
	public affiliation: string;
	public phoneNumber: string;
	public organizationalTitle: string;
	public isBCBA?: boolean;
	public biography: string;
	public featured: boolean;
	public avatar: string;
	public certificantNumber: string;
	public genderIdentity: string;
	public raceEthnicity: string;
	public pronouns: string;
	public organizationId?: number;
	public conferenceId?: number;
	public adminOrganizationId?: number;
}
export class JobSearchParameters
{
	public query: string;
	public longitude?: number;
	public lattitude?: number;
	public locationTextSearch: string;
	public locationSearchCity: string;
	public locationSearchState: string;
	public radiusInMiles?: number;
	public salaryMax?: number;
	public salaryMin?: number;
	public employmentType: EmploymentType[];
	public experience: Experience[];
	public locations: string[];
	public companies: number[];
	public singleCompanyId?: number;
	public page?: number;
	public pageSize?: number;
	public similiarJobID?: number;
	public sessionId: string;
	public currentSessionId: string;
	public recommendJobs: boolean;
}
export class CreateLocationViewModel
{
	public name: string;
	public street1: string;
	public street2: string;
	public countryCode: string;
	public state: string;
	public city: string;
	public postalCode: string;
	public industries: number[];
	public lattitude?: number;
	public longitude?: number;
}
export class CreateJobViewModel
{
	public companyName: string;
	public title: string;
	public teaser: string;
	public description: string;
	public department: string;
	public requirements: string;
	public responsibilities: string;
	public published: boolean;
	public experience?: Experience;
	public education: Education;
	public payInterval: PayInterval;
	public currency?: Currency;
	public keywords: string[];
	public skills: string[];
	public internalCode: string;
	public employmentType: EmploymentType;
	public payRateMin?: number;
	public payRateMax?: number;
	public remoteAllowed: boolean;
	public salaryPublic: boolean;
	public shareAndEarn: boolean;
	public companyLocationId: number;
	public applyUrl: string;
}
export class LocationViewModel
{
	public id?: number;
	public companyId: number;
	public name: string;
	public street1: string;
	public street2: string;
	public countryCode: string;
	public state: string;
	public jobCount: number;
	public city: string;
	public postalCode: string;
	public lattitude?: number;
	public longitude?: number;
}
export class SavedJobSearchParameters extends JobSearchParameters
{
	public id: number;
	public name: string;
	public emailNotifications: boolean;
	public locationText: string;
}
export class JobSearchHistoryParameters extends JobSearchParameters
{
	public searchDate: any;
	public userId?: number;
	public locationText: string;
}
export class NameValueViewModel
{
	public name: string;
	public value: string;
}
export class JobSearchResultViewModel
{
	public id: number;
	public companyId: number;
	public companyLocationId: number;
	public locationName: string;
	public title: string;
	public description: string;
	public employmentType: EmploymentType;
	public published: boolean;
	public closed: boolean;
	public jobSearchText: string;
	public companySearchText: string;
	public city: string;
	public state: string;
	public fromThirdPartyBoard: boolean;
	public externalId: string;
	public applyUrl: string;
	public postingUrl: string;
	public companyName: string;
	public teaser: string;
	public experience?: Experience;
	public favorite: boolean;
	public thumbnailUrl: string;
	public normalizedAnnualSalaryMax?: number;
	public normalizedAnnualSalaryMin?: number;
	public postDate?: any;
	public searchRank: number;
}
export class UpdateCompanyModel
{
	public name: string;
	public summary: string;
	public website: string;
	public corporateStructure?: CorporateStructure;
	public employeeCount?: EmployeeCount;
	public companyType?: CompanyType;
	public yearFounded?: number;
	public street: string;
	public street2: string;
	public city: string;
	public state: string;
	public postalCode: string;
	public countryCode: string;
	public twitterUrl: string;
	public facebookUrl: string;
	public linkedInUrl: string;
	public benefits: string[];
	public images: ImageViewModel[];
	public navIconUrl: string;
}
export class JobWithSummaryViewModel
{
	public id: number;
	public locationId: number;
	public title: string;
	public published: boolean;
	public closed: boolean;
	public city: string;
	public state: string;
	public countryCode: string;
	public views: number;
	public postDate?: any;
	public applys: number;
	public impressions: number;
}
export class CompanyViewModel
{
	public id: number;
	public name: string;
	public summary: string;
	public website: string;
	public corporateStructure?: CorporateStructure;
	public employeeCount?: EmployeeCount;
	public companyType?: CompanyType;
	public percentPropertiesComplete: number;
	public yearFounded?: number;
	public street: string;
	public street2: string;
	public city: string;
	public state: string;
	public postalCode: string;
	public countryCode: string;
	public navIconUrl: string;
	public twitterUrl: string;
	public facebookUrl: string;
	public linkedInUrl: string;
	public benefits: string[];
	public averageSalary: number;
	public jobCount: number;
}
export class JobStatisticsModel
{
	public impressions: number;
	public applys: number;
	public views: number;
}
export class JobViewModel
{
	public id: number;
	public companyId: number;
	public companyName: string;
	public title: string;
	public teaser: string;
	public published: boolean;
	public closed: boolean;
	public description: string;
	public postDate?: any;
	public department: string;
	public requirements: string;
	public responsibilities: string;
	public experience: Experience;
	public education: Education;
	public payInterval: PayInterval;
	public currency: Currency;
	public keywords: string[];
	public skills: string[];
	public internalCode: string;
	public employmentType: EmploymentType;
	public payRateMin?: number;
	public payRateMax?: number;
	public remoteAllowed: boolean;
	public shareAndEarn: boolean;
	public featured: boolean;
	public salaryPublic: boolean;
	public fromThirdPartyBoard: boolean;
	public externalId: string;
	public applyUrl: string;
	public favorite: boolean;
	public views: number;
	public modifiedDate: any;
	public companyLocation: LocationViewModel;
	public company: CompanyViewModel;
}
export class JobSearchResultsViewModel
{
	public resultCount: number;
	public jobs: JobSearchResultViewModel[];
	public companies: NameValueViewModel[];
	public page: number;
	public locations: NameValueViewModel[];
	public jobSearchHistoryId: number;
}
export class LoginViewModel
{
	public email: string;
	public password: string;
	public mobileUserType?: MobileUserType;
}
export class StringViewModel
{
	public value: string;
}
export class DateTimeViewModel
{
	public value?: any;
}
export class OpenTokAuthModel
{
	public sessionId: string;
	public token: string;
}
export class BooleanViewModel
{
	public value: boolean;
}
export class MembershipProfileViewModel
{
	public id: number;
	public user: InstructorViewModel;
	public membershipType: MembershipTypeViewModel;
	public status: MembershipStatus;
	public renewals: MembershipRenewalViewModel[];
}
export class MembershipRenewalViewModel
{
	public membershipType: MembershipTypeViewModel;
	public price: number;
	public renewalDate: any;
	public expiresDate: any;
}
export class MembershipTypeViewModel
{
	public id: number;
	public name: string;
	public price: number;
}
export class ProfileSearchParams
{
	public currentPage: number;
	public pageSize: number;
	public membershipType: number[];
	public status?: MembershipStatus;
	public searchText: string;
	public sort?: MemberProfileSort;
}
export class RegistrationViewModel
{
	public email: string;
	public password: string;
	public firstName: string;
	public lastName: string;
}
export class ResetPasswordViewModel
{
	public userId: number;
	public resetToken: string;
	public newPassword: string;
}
export class CEUScanAdvertisement
{
	public assetPath: string;
	public linkUrl: string;
}
export class AvatarViewModel
{
	public id: number;
	public avatar: string;
	public slug: string;
}
export class UserAuthenticationViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public token: string;
	public superUser: boolean;
}
export class UserLookupViewModel
{
	public found: boolean;
	public firstName: string;
	public lastName: string;
	public credentials: string;
	public office365Auth: boolean;
	public id?: number;
}
export class StripeDataViewModel
{
	public available: number;
	public pending: number;
	public weeklySum: number;
	public memberSince: any;
	public weeklyCount: number;
}
export class UserProfileViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public alternateEmail: string;
	public phoneNumber: string;
	public credentials: string;
	public biography: string;
	public pronouns: string;
	public certificantNumber: string;
	public flMhPsychCertificantNumber: string;
	public stripeLinked: boolean;
	public referralToken: any;
	public avatar: string;
	public emailVerified: boolean;
	public organizations: OrganizationViewModel[];
	public sponsorOrganizations: SponsorOrganizationViewModel[];
	public affiliation: string;
}
export class UserSubscriptionViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public stripeCustomerId: string;
	public paymentMethodLast4: string;
	public cardBrand: string;
	public stripeSubscriptionId: string;
	public hasCardOnFile: boolean;
	public hasSubscription: boolean;
}
export class UserCertificationViewModel
{
	public id: number;
	public certificationBody: CertificationBodyViewModel;
	public certificationBodyId: number;
	public certificationNumber: string;
}
export class UpdateProfileViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public alternateEmail: string;
	public phoneNumber: string;
	public avatar: string;
	public certificantNumber: string;
	public flMhPsychCertificantNumber: string;
	public biography: string;
	public organizationalTitle: string;
	public credentials: string;
	public base64Image: string;
	public pronouns: string;
	public affiliation: string;
	public isBCBA?: boolean;
}
export class EventCompletionViewModel
{
	public courseEventId: number;
	public metCEUCriteria: boolean;
	public ceuFeedbackSubmitted: boolean;
	public ceuParticipationDate?: any;
	public participationModality: ParticipationModality;
}
export class CourseCompletionViewModel
{
	public courseEventId: number;
	public metCEUCriteria: boolean;
	public ceuFeedbackSubmitted: boolean;
	public ceuParticipationDate?: any;
	public participationModality: ParticipationModality;
}
export class SpreadsheetMetaData
{
	public worksheetName: string;
	public columnNames: string[];
}
export class UploadSpreadsheetModel
{
	public assetPath: string;
	public worksheetName: string;
	public columnMappings: number[];
}
export class ExcelExportModel
{
	public fileName: string;
	public fileData: number[];
}
export class RegistrantImportModel
{
	public firstName: string;
	public lastName: string;
	public city: string;
	public state: string;
	public email: string;
	public packageId: number;
	public hasCEUs: boolean;
	public eventAddons?: number;
	public title: string;
	public affiliation: string;
	public credentials: string;
}
export class UserViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public phoneNumber: string;
	public avatar: string;
	public slug: string;
	public searchText: string;
	public emailVerified: boolean;
}
export class PayoutViewModel
{
	public stripeTransferId: string;
	public payoutDate: any;
	public amount: number;
}
export class ConferenceExpenseViewModel
{
	public id: number;
	public amount: number;
	public description: string;
	public dateTime: any;
}
export class ConferenceReconciliationData
{
	public payouts: PayoutViewModel[];
	public expenses: ConferenceExpenseViewModel[];
	public packageRegistrationCount: number;
	public totalReceipts: number;
	public caspSponsorFeeOverride?: number;
	public stripeFees: number;
	public behaviorLiveFee: number;
	public platformMinumum: number;
	public platformShare: number;
	public companyShare: number;
	public transferredToDate: number;
	public totalExpenseAmount: number;
	public individualRegistrationCount: number;
	public individualTotalReceipts: number;
	public individualBehaviorLivePercentage: number;
	public individualStripeFees: number;
	public individualPlatformLiveShare: number;
	public totalPlatformShare: number;
	public individualCompanyShare: number;
	public totalCompanyShare: number;
	public outstandingTransfer: number;
	public donationCount: number;
	public donationTotalReceipts: number;
	public donationStripeFees: number;
	public donationCompanyShare: number;
	public sponsorshipOrders: number;
	public sponsorshipTotalReceipts: number;
	public sponsorshipStripeFees: number;
	public sponsorshipCompanyShare: number;
	public onlineCount: number;
	public onSiteCount: number;
	public stripePercentage: number;
	public speakerPayments: number;
}
export class ExistingConferenceRegistrationViewModel
{
	public id: number;
	public conferenceId: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public packageName: string;
	public packageId: number;
}
export class CreateExistingConferenceRegistrationViewModel
{
	public firstName: string;
	public lastName: string;
	public email: string;
	public packageId: number;
}
export class UserSummaryViewModel extends UserViewModel
{
	public courseEventCount: number;
	public fullAccount: boolean;
	public fullNameReverse: string;
	public stripeConnectedAccountId: string;
	public checkedForMerge: boolean;
}
export class ExistingConferenceRegistrationResultsViewModel
{
	public count: number;
	public users: ExistingConferenceRegistrationViewModel[];
}
export class UserSearchResultsViewModel
{
	public count: number;
	public users: UserSummaryViewModel[];
}
export class PagedActiveUserViewModel
{
	public users: ActiveUserViewModel[];
	public count: number;
	public totalCount: number;
	public currentPage: number;
	public randomUserIds: number[];
	public totalPages: number;
	public onScreenUsers: ActiveUserViewModel[];
}
export class PagedLobbyActiveUserViewModel
{
	public users: ActiveLobbyUserViewModel[];
	public count: number;
	public currentPage: number;
	public randomUserIds: number[];
	public totalPages: number;
}
export class ActiveUserViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public eventId: number;
	public connected: boolean;
	public allowOnScreen: boolean;
	public city: string;
	public state: string;
	public stateCode: string;
	public country: string;
	public countryCode: string;
	public zip: string;
	public flag: string;
	public device: string;
	public os: string;
	public browser: string;
}
export class ActivePosterUserViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public posterId: number;
	public connected: boolean;
}
export class BrandViewModel
{
	public brandName: string;
	public logoPathLightBackground: string;
	public logoPathDarkBackground: string;
	public iosAppLink: string;
	public androidAppLink: string;
	public supportAddress: string;
}
export class SponsorshipConferenceScanResultViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public city: string;
	public state: string;
	public email: string;
	public phoneNumber: string;
	public pronouns: string;
	public createdDate: any;
	public affiliation: string;
	public notes: string;
	public credentials: string;
	public conferenceId: number;
	public sponsorOrganizationId: number;
}
export class ActiveExhibitUserViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public exhibitId: number;
	public connected: boolean;
	public checkedIn: boolean;
	public websiteClicked: boolean;
	public downloadedFiles: boolean;
	public turnedOnCamera: boolean;
	public visitCount: number;
	public chatCount: number;
	public engagementIndex: number;
	public pronouns: string;
	public affiliation: string;
	public notes: string;
	public credentials: string;
	public visitDateTime: any;
	public exitDateTime?: any;
}
export class ActiveLobbyUserViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public lobbyId: number;
	public connected: boolean;
}
export enum SegmentStatus {
	NotStarted = 1,
	InProgress = 2,
	Completed = 3,
	Failed = 4,
	MaxQuizAttempts = 5
}
export enum CEUType {
	Standard = 1,
	Ethics = 2,
	Supervision = 3
}
export enum SegmentType {
	Video = 1,
	Assessment = 2
}
export enum TaskSortOrder {
	Default = 1,
	DueDate = 2,
	Assignee = 3
}
export enum CourseEventStatus {
	InReview = 1,
	Rejected = 2,
	Draft = 3,
	PendingRecording = 4,
	Published = 5,
	PublishedInternal = 6,
	Unlisted = 7,
	ReleaseWithConference = 8,
	Archived = 9,
	NotForSale = 10,
	TechnicalIssue = 11
}
export enum RecordingMethod {
	Self = 1,
	Remote = 2,
	Studio = 3
}
export enum FileType {
	Pdf = 1,
	Word = 2,
	Excel = 3,
	Powerpoint = 4,
	Image = 5,
	Zip = 6,
	Unknown = 7
}
export enum CourseSpeakerType {
	Individual = 1,
	Panel = 2
}
export enum OrderBy {
	CreatedDate = 1,
	ModifiedDate = 2,
	Name = 3,
	MissingCEUQuestions = 4,
	OriginalAirDate = 5
}
export enum ReportingPeriod {
	Today = 1,
	Last7Days = 2,
	Last4Weeks = 3,
	Last3Months = 4,
	Last12Months = 5,
	MonthToDate = 6,
	QuarterToDate = 7,
	YearToDate = 8,
	AllTime = 9
}
export enum EventOrderBy {
	CreatedDate = 1,
	ModifiedDate = 2,
	EventDate = 3,
	Name = 4
}
export enum PageElementType {
	HTMLContent = 1,
	OnDemandSearch = 2,
	PackageListing = 3,
	ExhibitListing = 4,
	SpeakerListing = 5,
	LiveEventListing = 6,
	ConferenceDate = 7,
	OnDemandListing = 8,
	PosterListing = 9,
	AdRotator = 10,
	Schedule = 11,
	ProgramExport = 12,
	OrganizationEventListing = 13,
	ProductListing = 14,
	PDFViewer = 15,
	ConferenceListing = 16,
	BundleListing = 17
}
export enum OrganizationRole {
	Admin = 1,
	User = 2,
	Instructor = 4
}
export enum PackageRequirement {
	None = 0,
	EDUAddress = 1,
	MembersOnly = 2
}
export enum InOut {
	In = 0,
	Out = 1
}
export enum CEUScanMode {
	Search = 0,
	Scan = 1
}
export enum MobileUserType {
	iOS = 1,
	Android = 2
}
export enum YesNoUnsure {
	No = 0,
	Yes = 1,
	Unsure = 2
}
export enum PaperApprovalStatus {
	Draft = 0,
	Submitted = 1,
	InReview = 2,
	Accepted = 3,
	Rejected = 4,
	Withdrawn = 5,
	ChangesNeeded = 6,
	Finalized = 7
}
export enum PaperActivityType {
	StatusChanged = 1,
	EmailSent = 2,
	EmailReceived = 3,
	NoteAdded = 4,
	NoteCommentAdded = 5,
	AssigneeUpdated = 6,
	PropertyUpdated = 7,
	ReviewAdded = 8
}
export enum RecipientType {
	To = 1,
	Cc = 2,
	Bcc = 3
}
export enum OrganizationIntegrationType {
	MemberClicks = 1,
	WordPressGravityForms = 2,
	Novi = 3,
	HubspotMarketingEvents = 4,
	MemberPress = 5,
	Django = 6
}
export enum CEUInstructorStatus {
	Unknown = 0,
	Confirmed = 1,
	Needed = 2
}
export enum PaperSubmissionSort {
	SubmissionDate = 0,
	AverageRating = 1,
	Duration = 2,
	Alphabetical = 3,
	Type = 4,
	ReviewCount = 5
}
export enum BadgeElementType {
	Text = 1,
	Image = 2,
	QRCode = 3,
	AddonList = 4,
	EventAddonList = 5
}
export enum AssetType {
	AnyFile = 1,
	AnyImage = 2,
	Vector = 4,
	VectorPDF = 8,
	Raster = 16,
	RasterPDF = 32,
	Video = 64,
	PDF = 128,
	Word = 256
}
export enum VoucherType {
	ComplmentaryRegistration = 1,
	ExhibitBoothPass = 2,
	Generic = 3
}
export enum ExhibitType {
	OnSiteAndOnline = 1,
	OnSiteOnly = 2,
	OnlineOnly = 3
}
export enum HorizontalAlignment {
	Left = 0,
	Right = 1,
	Center = 2
}
export enum RegistrantSort {
	RegistrationDateAsc = 0,
	RegistrationDateDesc = 1,
	Alphabetical = 2,
	Package = 3
}
export enum FontStyle {
	Regular = 0,
	Bold = 1,
	Italic = 2,
	BoldItalic = 3
}
export enum MembershipStatus {
	Active = 1,
	Inactive = 2,
	Expired = 3
}
export enum ConferenceModality {
	Hybrid = 1,
	Virtual = 2,
	InPerson = 3,
	HybridAV = 4
}
export enum MemberProfileSort {
	NameAsc = 0,
	NameDesc = 1
}
export enum RegistrationFieldType {
	Text = 1,
	SingleSelect = 2,
	MultiSelect = 3
}
export enum BadgeSize {
	FourByThree = 1,
	FourBySix = 2
}
export enum ConferenceConnectionState {
	Pending = 1,
	Accepted = 2,
	Rejected = 3
}
export enum ConferenceMobileTileType {
	PresenterListing = 1,
	SessionListing = 2,
	SponsorListing = 3,
	ExhibitListing = 4,
	MapView = 5,
	AttendeeListing = 6,
	PDFListing = 7,
	WebView = 8,
	HTMLText = 9,
	ChatGroups = 10,
	SocialWall = 11,
	Announcements = 12,
	Survey = 13,
	Page = 14
}
export enum PushNotificationAction {
	ConnectionRequestSent = 1,
	ConnectionRequestAccepted = 2,
	ChatReceived = 3,
	ChatGroupRequested = 4,
	NewAnnouncement = 5
}
export enum CampaignStatus {
	Draft = 0,
	Scheduled = 1,
	Sending = 2,
	Sent = 3,
	Archived = 4
}
export enum CampaignType {
	Email = 1,
	SMS = 2,
	Push = 3
}
export enum CampaignListType {
	Dynamic = 1,
	Static = 2
}
export enum SpreadsheetImportType {
	ConferenceRegistrant = 0,
	EventRegistrant = 1,
	Employee = 2
}
export enum SurveyState {
	Draft = 0,
	Published = 1,
	Archived = 2
}
export enum FilterOperator {
	EqualTo = 1,
	NotEqualTo = 2
}
export enum LedgerEntryType {
	CourseSold = 1,
	Referral = 2,
	EventRegistration = 3,
	Refund = 4,
	Bundle = 5
}
export enum ReaderMode {
	ScanIn = 0,
	ScanOut = 1
}
export enum ParticipationModality {
	None = 0,
	InPerson = 1,
	LiveStream = 2,
	OnDemand = 3
}
export enum RegistrationModality {
	InPerson = 1,
	LiveStream = 2,
	CheckedIn = 3,
	NotCheckedIn = 4
}
export enum ProgramRuleType {
	MatchAllEvents = 0,
	SourcePaperType = 1,
	HasTag = 2,
	TitleContains = 3
}
export enum RoomBillingMode {
	SelfPay = 0,
	RoomToMaster = 1,
	AllChargesToMaster = 2
}
export enum AdCampaignType {
	Image = 1,
	Text = 2,
	Video = 3
}
export enum EmploymentType {
	FullTime = 1,
	PartTime = 2,
	Freelance = 3,
	Internship = 4,
	Volunteer = 5
}
export enum CorporateStructure {
	Public = 1,
	Private = 2,
	NonProfit = 3
}
export enum CompanyType {
	Employer = 1,
	Recruiter = 2,
	NonProfit = 3,
	JobBoard = 4
}
export enum Experience {
	EntryLevel = 1,
	MidLevel = 2,
	SeniorLevel = 3,
	Director = 4,
	Executive = 5
}
export enum EmployeeCount {
	LessThanFive = 1,
	FiveToTwenty = 2,
	TwentyToFifty = 3,
	FiftyToOneHundred = 4,
	OneHundredToFiveHundred = 5,
	FiveHundredToOneThousand = 6,
	OneThousandToTenThousand = 7,
	TenThousandPlus = 8
}
export enum PayInterval {
	NA = 0,
	Hourly = 1,
	Annually = 2
}
export enum Education {
	Unspecified = 1,
	SomeHighSchool = 2,
	HighSchool = 3,
	VocationalHighSchoolDiploma = 4,
	Certification = 5,
	Vocational = 6,
	SomeCollege = 7,
	Professional = 8,
	VocationalDegree = 9,
	Associate = 10,
	Bachelors = 11,
	Masters = 12,
	Doctorate = 13
}
export enum Currency {
	USD = 1,
	EUR = 2,
	GBP = 3,
	CAD = 4,
	AUD = 5,
	INR = 6
}
export enum ConferenceTaskStatusType {
	NotStarted = 0,
	InProgress = 1,
	Completed = 2
}
export enum WowzaState {
	Started = 0,
	Stopped = 1,
	Starting = 2,
	Stopping = 3,
	Resetting = 4
}
